import React, { Component } from 'react';
import { 
  Link as RouterLink,
  Redirect
} from 'react-router-dom';

import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import AdminChannelCard from "./cards/AdminChannelCard";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Modal from '@material-ui/core/Modal';

import CircularProgress from '@material-ui/core/CircularProgress';

import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import axios from 'axios';
import AdminFileManager from './AdminFileManager';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200
  },
  grow: {
    flexGrow: 1,
  },
  grid: {
    width: 1000
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inlineTextInput: {
    marginTop: 8,
  },
  bottomMargin: {
    marginBottom: 16,
  },
  leftMargin: {
    marginLeft: 16,
    marginBottom: 16
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 1000
  },
  bigSpinner: {
    marginTop: theme.spacing(6)
  },
  spinner: {
    marginLeft: theme.spacing(1)
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
 
class AdminChannelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requiresApproval: true,
      admins: [],
      dataWasFetched: false,
      redirectToDashBoard: false,
      modalOpened: false,
      updateMessage: "",
      channelName: "",
      channelDescription: "",
      category: "",
      pageLoading: true,
      loading: false,
      deleteLoading: false
    };

    this._addFile = this._addFile.bind(this);
    this._deleteChannel = this._deleteChannel.bind(this);
    this._submitForm = this._submitForm.bind(this);
    this._updateFile = this._updateFile.bind(this);
    this._deleteFile = this._deleteFile.bind(this);
  }

  channelNameRef = React.createRef();
  channelDescriptionRef = React.createRef();

  componentDidMount() {
    const { match: { params } } = this.props;
    const fetchCategories = axios.post('/api/admin/fetchCategories', {});
    const fetachAdmins    = axios.post('/api/admin/fetchAdmins', {});
    const fetchChannel    = axios.post('/api/admin/fetchChannel', {channelId: params['id']});

    Promise.all([fetchCategories,fetachAdmins,fetchChannel]).then((responses) => {
      if (responses.filter(r => !!r.data)) {
        const categories = responses[0].data;
        const admins     = responses[1].data;
        const channel    = responses[2].data;
        this.setState({
          pageLoading: false,
          category: channel.category || "",
          channelDescription: channel.channelDescription || "",
          channelName: channel.channelName || "",
          files: channel.files,
          isOpenAccess: channel.isOpenAccess,
          isPrivate: channel.isPrivate,
          requiresApproval: channel.requiresApproval,
          categories: categories, 
          admins: admins, 
          dataWasFetched: true
        })
      } else {
        this.props.history.push('/seConnecter')
      }

    })
  }

  _updateFile(fileIndex, file) {
    let files = [...this.state.files];
    files[fileIndex] = file;
    this.setState({files: files}, () => {this._submitForm()})
  }

  _deleteFile(fileIndex) {
    let files = [...this.state.files];
    files.splice(fileIndex, 1)
    this.setState({files: files}, () => {this._submitForm()})
  }

  _deleteChannel() {
    this.setState({deleteLoading: true})
    axios.post('/api/admin/deleteChannel', {channelId: this.props.match.params['id']}).then(r => {
      if (r.data) {
        this.setState({deleteLoading: false, redirectToDashBoard: true})
      } else {
        this.setState({deleteLoading: false});
        console.log(r.data)
      }
    })
  }

  _addFile() {
    axios.post('/api/admin/createFile', {}).then(r => {
      if (r.data) {
        let files = [...this.state.files];
        files.push(r.data);
        this.setState({files: files}, () => {this._submitForm()});
      } else {
        console.log(r.data)
      }
    })
  }

  _submitForm() {
    this.setState({loading: true})
    if (this.state['channelName'] == "" || this.state['channelDescription'] == "") {
      this.channelNameRef.current.validate(this.state['channelName'],true);
      this.channelDescriptionRef.current.validate(this.state['channelDescription'],true);
      this.setState({loading: false});
    } else {
      axios.post('/api/admin/editChannel', {
        channelId: this.props.match.params['id'],
        channelName: this.state['channelName'],
        channelDescription: this.state['channelDescription'],
        isPrivate: !!this.state['isPrivate'],
        isOpenAccess: !!this.state['isOpenAccess'],
        requiresApproval: !!this.state['requiresApproval'],
        approvalsBy: this.state['approvalsBy'],
        category: this.state['category'],
        files: this.state['files']
      }).then(r => {
        if (r.data) {
          this.setState({loading: false});
          console.log(r.data);
        } else {
          this.setState({loading: false});
          console.log(r.data)
        }
      })
    }


  }

  render() {
    const { classes } = this.props;
    if (this.state.redirectToDashBoard) {
      return (<Redirect to='/admin' />)
    } else {
      if (this.state.pageLoading) {
        return (<Grid container justify="center"><CircularProgress className={classes.bigSpinner} size={48} /></Grid>)
      } else {
        return (
          this.state.dataWasFetched ? <Container component="main" maxWidth="md">
            <CssBaseline />
              <div className={classes.paper}>
                <Modal
                  open={this.state.modalOpened}
                  onClose={() => this.setState({modalOpened: false})}
                  className={classes.modal}
                >
                  <div className={classes.modalContent}>
                    <Typography component="h1" variant="h5">
                      Envoyer un message aux abonnés de la chaîne
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="updateMessage"
                      label="Message de mise à jour"
                      id="updateMessage"
                      multiline
                      rows={10}
                      value={this.state.updateMessage}
                      onChange={e => this.setState({updateMessage: e.target.value})}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        axios.post('/api/admin/sendNotificationEmail', {
                          updateMessage: this.state.updateMessage,
                          channelId: this.props.match.params['id']
                        }).then((response) => {
                          if (response.data) {
                            this.setState({
                              modalOpened: false,
                              updateMessage: ""
                            })
                          } else {
                            console.log(response.data)
                          }
                        })
                      }}
                    >
                      Envoyer le courriel de notification
                    </Button>
                  </div>
                </Modal>
                <Typography component="h1" variant="h5">
                  Éditer un dépôt
                </Typography>
                <ValidatorForm 
                  className={classes.form} 
                  onError={errors => console.log('errors', errors)}
                  onSubmit={this._submitForm}
                >     
                  <TextValidator
                    ref={this.channelNameRef}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="channelName"
                    label="Nom du dépôt"
                    name="channelName"
                    autoFocus
                    value={this.state.channelName}
                    onBlur={this._submitForm}
                    onChange={e => this.setState({channelName: e.target.value})}
                    validators={['required']}
                    errorMessages={['Vous devez remplir ce champs']}
                  />
                  <TextValidator
                    ref={this.channelDescriptionRef}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="channelDescription"
                    label="Description du dépôt"
                    id="channelDescription"
                    multiline
                    rows={6}
                    value={this.state.channelDescription}
                    onBlur={this._submitForm}
                    onChange={e => this.setState({channelDescription: e.target.value})}
                    validators={['required']}
                    errorMessages={['Vous devez remplir ce champs']}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isPrivate}
                        onChange={e => this.setState({isPrivate: e.target.checked}, () => this._submitForm())}
                        name="isPrivate"
                        color="primary"
                      />
                    }
                    label="Dépôt privé"
                  />
                  <div className={classes.bottomMargin}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Type d'accès</FormLabel>
                      <RadioGroup 
                        aria-label="Type d'accès" 
                        name="accessType" 
                        color="primary"
                        value={this.state.requiresApproval ? 'requiresApproval' : 'isOpenAccess'} 
                        onChange={(e) => {
                          this.setState({
                            requiresApproval: e.target.value == 'requiresApproval',
                            isOpenAccess: e.target.value == 'isOpenAccess'
                          }, () => this._submitForm())}}
                      >
                        <FormControlLabel value="requiresApproval" control={<Radio color="primary" />} label="Requiert une approbation" />
                        <FormControlLabel value="isOpenAccess" control={<Radio color="primary" />} label="Accès libre" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth variant="outlined" className={classes.grow}>
                      <InputLabel htmlFor="categorySelect">Catégorie</InputLabel>
                      <Select
                        native
                        value={this.state.category}
                        onChange={e => this.setState({category: e.target.value == "" ? undefined : e.target.value}, () => this._submitForm())}
                        label="Age"
                        id="categorySelect"
                        inputProps={{
                          name: 'categorySelect',
                          id: 'categorySelect',
                        }}
                      >
                        <option aria-label="None" value="" />
                        {this.state.categories.map(category => (<option key={category._id} value={category._id}>{category.categoryName}</option>))}
                      </Select>
                    </FormControl>
                  </div>
                  {(this.state.category && this.state.requiresApproval) &&
                    <div className={classes.leftMargin}>
                      <div className={classes.bottomMargin}>Approbateurs</div>
                      <div><Typography variant="body2" color="textSecondary" component="p">{this.state.categories.find(c => c._id == this.state.category).approvalsBy.map(s => this.state.admins.find(a => a._id == s)).map(a => a.email).join(', ')}</Typography></div>
                    </div>
                  }
                  <List subheader={<ListSubheader>Fichiers <span onClick={this._addFile}>+</span></ListSubheader>}>
                    {this.state.files.map((f,i) => (
                      <AdminFileManager 
                        key={f._id}
                        fileIndex={i}
                        file={f}
                        updateFile={this._updateFile}
                        deleteFile={this._deleteFile}
                      />)
                    )}
                  </List>
                </ValidatorForm>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={this.state.loading}
                  onClick={() => this.props.history.push(`/admin`)}
                >
                  Soumettre les modifications{this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {this.setState({modalOpened: true})}}
                >
                  Notifier les abonnés de la chaîne de changements
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={this.state.deleteLoading}
                  className={classes.submit}
                  onClick={this._deleteChannel}
                >
                  Supprimer le dépôt{this.state.deleteLoading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                </Button>
              </div>
          </Container> : null
        )
      }
    }
  }
}


export default withStyles(styles)(AdminChannelForm);