import React, { Component } from 'react';

import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Link from '@material-ui/core/Link';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  }
});

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="md">
        <Typography variant="h4">Foire aux questions</Typography>
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Pourquoi certaines données sont à accès limités?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="div">
                <p>
                  Certaines données, notamment les données désagrégées de l'enquête Origine-Destination, sont fournies uniquement sur demande, et leur utilisation sous condition de l'acceptation d'une licence. 
                  Cette licence est gratuite pour les organisations publiques et de recherche. Les autres organisations doivent doivent acquitter un frais pour obtenir cette licence.
                </p>
                <p>
                  Pour les données de l'enquête Origine-Destination, l'ARTM rend disponible à tous de nombreuses autres données,
                  dont les <Link href="https://www.artm.quebec/faits-saillants-eod-2018/">faits saillants</Link>, 
                  en plus d'une <Link href="https://resultatsod.artm.quebec/">plateforme interactive</Link> permettant d'explorer les résultats.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/*<Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">Question 2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Réponse 2
              </Typography>
            </AccordionDetails>
          </Accordion>*/}
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(Faq);