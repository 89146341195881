import React, { Component } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Redirect,
} from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import ErrorIcon from "@material-ui/icons/Error";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Recaptcha from "react-recaptcha";
import config from "../../config/client.json";

let recaptchaInstance;

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirmation: "",
      redirectToLogin: false,
      captchaToken: null,
      message: "",
      errorMessage: "",
      loading: false,
      success: false,
      showPassword: false,
      showRepeatPassword: false,
    };

    this._resetPassword = this._resetPassword.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  _resetPassword(event) {
    event.preventDefault();
    const {
      match: { params },
    } = this.props;
    this.setState({ loading: true });
    axios
      .post("/api/resetPassword", {
        token: params["token"],
        password: this.state.password,
        captchaToken: this.state.captchaToken,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              loading: false,
              success: true,
              message:
                "Votre mot de passe a été changé avec succès. Vous serez redirigés vers la page de connexion",
            },
            () => {
              setTimeout(() => {
                this.setState({ redirectToLogin: true });
              }, 5000);
            }
          );
        } else {
          recaptchaInstance.reset();
          this.setState(
            { loading: false, success: false, errorMessage: res.data.message },
            () => {
              setTimeout(() => {
                this.setState({ errorMessage: "" });
              }, 10000);
            }
          );
        }
      });
  }

  render() {
    const { classes } = this.props;
    const {
      match: { params },
    } = this.props;
    if (this.state.redirectToLogin === true) {
      return <Redirect to="/seConnecter" />;
    } else {
      return (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          {}
          {this.state.loading && !this.state.success ? (
            <div className={classes.paper}>
              <CircularProgress />
            </div>
          ) : this.state.success ? (
            <div className={classes.paper}>
              <Typography component="h1" variant="h5" align="center">
                {this.state.message}
              </Typography>
            </div>
          ) : (
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <ErrorIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Réinitialiser le mot de passe
              </Typography>
              <ValidatorForm
                className={classes.form}
                onError={(errors) => console.log("errors", errors)}
                onSubmit={this._resetPassword}
              >
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  validators={["required", "minStringLength: 8"]}
                  errorMessages={[
                    "Vous devez remplir ce champs",
                    "Veuillez choisir un mot de passe de 8 caractères ou plus",
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Activer/Désactiver l'affichage du mot de passe"
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                          onMouseDown={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                          tabIndex="-1"
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirmer le mot de passe"
                  type={this.state.showRepeatPassword ? "text" : "password"}
                  id="passwordConfirmation"
                  autoComplete="current-password"
                  onChange={(e) =>
                    this.setState({ passwordConfirmation: e.target.value })
                  }
                  value={this.state.passwordConfirmation}
                  validators={["isPasswordMatch", "required"]}
                  errorMessages={[
                    "Le mot de passe ne correspond pas",
                    "Vous devez remplir ce champs",
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Activer/Désactiver l'affichage du mot de passe"
                          onClick={() =>
                            this.setState({
                              showRepeatPassword:
                                !this.state.showRepeatPassword,
                            })
                          }
                          onMouseDown={() =>
                            this.setState({
                              showRepeatPassword:
                                !this.state.showRepeatPassword,
                            })
                          }
                          tabIndex="-1"
                        >
                          {this.state.showRepeatPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex" width="100%">
                  <Box m="auto">
                    <Recaptcha
                      sitekey={config.publicCaptchaToken}
                      hl="fr"
                      ref={(e) => (recaptchaInstance = e)}
                      render="explicit"
                      onloadCallback={() => console.log("rendered")}
                      verifyCallback={(response) => {
                        console.log(response);
                        this.setState({ captchaToken: response });
                      }}
                    />
                  </Box>
                </Box>
                {this.state.errorMessage != "" && (
                  <Typography
                    className={classes.errorMessage}
                    component="h6"
                    variant="subtitle1"
                    align="center"
                    color="secondary"
                  >
                    {this.state.errorMessage}
                  </Typography>
                )}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                  disabled={this.state.loading}
                >
                  Définir le nouveau mot de passe
                  {this.state.loading ? (
                    <CircularProgress className={classes.spinner} size={16} />
                  ) : null}
                </Button>
              </ValidatorForm>
            </div>
          )}
        </Container>
      );
    }
  }
}

export default withStyles(styles)(ResetPassword);
