import React, { Component } from 'react';
import { 
  Link as RouterLink
} from 'react-router-dom';
import axios from 'axios';

import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';


import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const styles = theme => ({
  root: {
    maxWidth: 1000
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inlineRight: {
    width: '20%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center'
    }
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  bottomMargin: {
    marginBottom: 16,
  },
  spinner: {
    marginLeft: theme.spacing(1)
  },
})

class AdminCategoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: props.categoryName || "",
      categoryDescription: props.categoryDescription || "",
      approvalsBy: props.approvalsBy,
      admins: props.admins || [],
      previousValues: {},
      deleteLoading: false,
      saveLoading: false
    };

    this._saveCategory = this._saveCategory.bind(this);
    this._deleteCategory = this._deleteCategory.bind(this);
  }

  _saveCategory() {
    this.setState({saveLoading: true})
    axios.post('/api/admin/editCategory', {
      categoryId: this.props.categoryId, 
      categoryName: this.state.categoryName, 
      categoryDescription: this.state.categoryDescription,
      approvalsBy: this.state.approvalsBy
    }).then((res) => {
      this.setState({saveLoading: false})
      if (res.data) {
        this.props.closeForm(this.props.categoryId);
        this.props.fetchData();
      } else {
        console.log(res);
      }

    })
  }

  _deleteCategory() {
    this.setState({deleteLoading: true})
    axios.post('/api/admin/deleteCategory', {
      categoryId: this.props.categoryId
    }).then((res) => {
      this.setState({deleteLoading: true})
      if (res.data) {
        this.props.closeForm(this.props.categoryId);
        this.props.fetchData();
      } else {
        console.log(res);
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>

        {!this.props.formIsOpen ? 
          <Card className={classes.root}>
            <CardHeader
              title={this.state.categoryName}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap'}}>
                {this.state.categoryDescription}
              </Typography>
            </CardContent>
            <CardContent>
                <div className={classes.bottomMargin}>Approbateurs</div>
                <div><Typography variant="body2" color="textSecondary" component="p">{this.state.approvalsBy.map(s => this.state.admins.find(a => a._id == s)).map(a => a.email).join(', ')}</Typography></div>
            </CardContent>
            <CardActions disableSpacing style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button onClick={() => {this.setState(
                {previousValues: {
                  categoryName: this.state.categoryName,
                  categoryDescription: this.state.categoryDescription,
                  approvalsBy: this.state.approvalsBy,
                }}, () => {this.props.openForm(this.props.categoryId)})}} >
                Éditer
              </Button>
            </CardActions> 
          </Card>
        : <Card className={classes.root}>
            <ValidatorForm 
              onError={errors => console.log('errors', errors)}
              onSubmit={this._saveCategory}
            >    
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                id="categoryName"
                label="Nom de la catégorie"
                name="categoryName"
                value={this.state.categoryName}
                onChange={e => this.setState({categoryName: e.target.value})}
                validators={['required']}
                errorMessages={['Vous devez remplir ce champs']}
              />
              <CardContent>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="categoryDescription"
                  label="Description de la catégorie"
                  name="categoryDescription"
                  value={this.state.categoryDescription}
                  onChange={e => this.setState({categoryDescription: e.target.value})}
                  validators={['required']}
                  errorMessages={['Vous devez remplir ce champs']}
                />
              </CardContent>
              <CardContent>
                <FormControl fullWidth variant="outlined" className={classes.bottomMargin}>
                  <InputLabel id="approvalsByLabel">Approbateur(s)</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="approvalsByLabel"
                    id="approvalsBy"
                    multiple
                    value={this.state.approvalsBy}
                    onChange={(e) => this.setState({approvalsBy: e.target.value})}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => selected.map(s => this.state.admins.find(a => a._id == s)).map(a => a.email).join(', ')}
                  >
                    {this.state.admins.map((admin) => (
                      <MenuItem key={admin._id} value={admin._id}>
                        <Checkbox color="primary" checked={this.state.approvalsBy.indexOf(admin._id) > -1} />
                        <ListItemText primary={admin.email} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              <CardActions disableSpacing style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button
                  disabled={this.state.deleteLoading || this.state.saveLoading}
                  onClick={this._deleteCategory}
                >
                  Supprimer{this.state.deleteLoading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                </Button>
                <Button
                  disabled={this.state.deleteLoading || this.state.saveLoading}
                  onClick={() => this.setState({...this.state.previousValues}, () => this.props.closeForm(this.props.categoryId) )}
                >
                  Fermer sans sauvegarder
                </Button>
                <Button
                  disabled={this.state.deleteLoading || this.state.saveLoading}
                  type="submit"
                >
                  Sauvegarder{this.state.saveLoading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card> }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AdminCategoryCard);