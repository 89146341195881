import React, {Component} from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router, 
  Link as RouterLink,
  Redirect
} from 'react-router-dom';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/styles/withStyles";
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spinner: {
    marginLeft: theme.spacing(1)
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      errorMessage: null,
      loading: false
    };
    this._login = this._login.bind(this);
  }

  _login(event) {
    this.setState({loading: true});
    event.preventDefault();
    axios.post('/api/login', {email: this.state.email, password: this.state.password}).then((res) => {
      if (res.data.success) {
        this.props.setUser({
          user: res.data.user.email,
          userContactInfo: res.data.user.contactInfo,
          subscribedTo: res.data.user.subscribedTo, 
          demands: res.data.user.demands, 
          authorizedFor: res.data.user.authorizedFor,
          isAdmin: res.data.user.isAdmin
        });
        return({success: true})
      } else {
        return({success: false})
      }
    }).then((res) => {
      if (res.success) {
        this.setState({loading: false, redirectToReferrer: true})
      } else {
        this.setState({loading: false, errorMessage: "Le nom d'usager ou le mot de passe sont erronés"}, () => {
          setTimeout(() => {this.setState({errorMessage: null})}, 10000)
        })
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/abonnements' } }
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />
    } else {
      return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Se connecter
              </Typography>
              <ValidatorForm 
                className={classes.form} 
                onError={errors => console.log('errors', errors)}
                onSubmit={this._login}
              >
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Courriel"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={e => this.setState({email: e.target.value})}
                  value={this.state.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Vous devez remplir ce champs', 'Vous devez entrer une adresse courriel valide']}
                />
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={e => this.setState({password: e.target.value})}
                  value={this.state.password}
                  validators={['required']}
                  errorMessages={['Vous devez remplir ce champs']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Activer/Désactiver l'affichage du mot de passe"
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}
                          onMouseDown={() => this.setState({showPassword: !this.state.showPassword})}
                          tabIndex="-1"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {this.state.errorMessage != "" && <Typography 
                  className={classes.errorMessage} 
                  component="h6" 
                  variant="subtitle1" 
                  align="center"
                  color="secondary"
                >
                  {this.state.errorMessage}
                </Typography>
                }
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={this.state.loading}
                >
                  Se connecter{this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link component={RouterLink} to="/recupererMotDePasse" variant="body2" style={{fontSize: 16}}>
                      J'ai oublié mon mot de passe
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/creerUnCompte" variant="body2" style={{fontSize: 16}}>
                      {"Créer un compte"}
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
        </Container>
      );
    }
  }
}

export default withStyles(styles)(SignIn);