import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  isAuthenticated,
  appLoaded,
  component: Component,
  ...rest
}) => (
  appLoaded ? <Route {...rest} render={(props) => (
    isAuthenticated ? (
      <Component 
        {...rest} 
        {...props}
        isAuthenticated={isAuthenticated}
        appLoaded={appLoaded}
      />
    ) : (
      <Redirect to={{
        pathname: '/seConnecter',
        state: { from: props.location }
      }} />
    )
  )}/> : null 
);
export default PrivateRoute;