import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "30px 60px",
    },
    form: {
      textAlign: "center",
      userSelect: "none",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    agreeButton: {
      marginTop: 20,
    },
    labelText: {
      display: "inline-block",
      width: "90%",
      textAlign: "left",
      verticalAlign: "top",
      marginTop: 8,
      marginLeft: 4,
    },
  })
);

export default function TermsAndConditionsDialog({
  open,
  handleClose,
  handleAccept,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  function acceptConditions() {
    if (!localStorage.getItem("termsAndConditions")) {
      localStorage.setItem("termsAndConditions", "accepted");
    }
    handleAccept();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.container}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Termes et conditions</Typography>
        <Typography component="div">
          <p>
            En accédant aux données ouvertes de l&apos;Autorité régionale de
            transport métropolitain (ARTM), vous confirmez avoir lu, compris et
            accepté les conditions de la présente licence, et vous vous engagez
            à respecter les obligations et modalités présentes dans la{" "}
            <a href="https://creativecommons.org/licenses/by/4.0/legalcode.fr">
              licence d&apos;utilisation
            </a>
            .
          </p>
          <div className={classes.form}>
            <Typography component="label">
              <Checkbox
                id="accept-terms-and-conditions"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                color="primary"
              />
              <span className={classes.labelText}>
                J&apos;ai lu et j&apos;accepte les conditions générales
                d&apos;utilisation de la licence{" "}
                <a href="https://creativecommons.org/licenses/by/4.0/legalcode.fr">
                  Attribution &mdash; Utilisation 4.0 International de Creative
                  Commons (CC-BY 4.0)
                </a>
              </span>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.agreeButton}
              onClick={acceptConditions}
              disabled={!checked}
            >
              Télécharger les données
            </Button>
          </div>
        </Typography>
      </div>
    </Dialog>
  );
}
