import React, { Component } from 'react';

import {
  BrowserRouter as Router, 
  Redirect,
  Link as RouterLink
} from 'react-router-dom';

import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Link from '@material-ui/core/Link';


import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';

import axios from 'axios';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200
  },
  grid: {
    width: 1000
  },
  marginTop: {
    marginTop: 24
  },
  smallMarginTop: {
    marginTop: 8
  },
  grow: {
    flexGrow: 1,
  },
  main: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    marginTop: theme.spacing(4)
  },
  bigSpinner: {
    marginTop: theme.spacing(6)
  },
  spinner: {
    marginLeft: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmRefusal: {
    backgroundColor: '#fafafa',
    padding: 30
  }
});
 
class AdminDemandForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToDashBoard: false,
      pageLoading: true,
      loading: false,
      refuseLoading: false,
      modalOpened: false,
      demand: {
        user: {
          contactInfo: {
            fullName: "",
            department: "",
            fullAddress: "",
            organismName: "",
            title: ""
          },
          email: ""
        },
        description: "",
        category: {
          categoryName: ""
        }
      },
      privateAccess: false,
      isPayingContract: false
    };

    this._approveDemand = this._approveDemand.bind(this);
    this._refuseDemand = this._refuseDemand.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    axios.post('/api/admin/fetchDemand', {demandId: params.id}).then((d) => {
      this.setState({
        pageLoading: false, 
        demand: d.data,
        privateAccess: d.data.privateAccess
      });
    })
  }

  _approveDemand() {
    this.setState({loading: true});
    const { match: { params } } = this.props;
    axios.post('/api/admin/approveDemand', {demandId: params.id, privateAccess: this.state.privateAccess, isPayingContract: this.state.isPayingContract}).then((d) => {
      this.setState({loading: false, redirectToDashBoard: true})
    })
  }

  _refuseDemand() {
    this.setState({refuseLoading: true});
    const { match: { params } } = this.props;
    axios.post('/api/admin/refuseDemand', {demandId: params.id}).then((d) => {
      this.setState({refuseLoading: false, redirectToDashBoard: true})
    })
  }
 
  render() {
    const { classes } = this.props;
    if (this.state.redirectToDashBoard === true) {
      return <Redirect to='/admin' />
    } else {
      if (this.state.pageLoading) {
        return(<Grid container justify="center"><CircularProgress className={classes.bigSpinner} size={48} /></Grid>)
      } else {
        if (this.state.demand.approved) {
          return (
            <Container component="main" maxWidth="sm" className={classes.paper}>
              <Grid container justify="center">
                <Box className={classes.marginTop}>
                <Typography variant="h6" component="h6">
                  Cette demande a déjà été approuvée
                </Typography>
                </Box>
              </Grid>
              <Grid container justify="center">
                <Box className={classes.marginTop}>
                <Typography className={classes.margin} variant="h6" color="inherit" component={RouterLink} to="/admin">
                  Retourner au tableau de bord administrateur
                </Typography>
                </Box>
              </Grid>
            </Container>
          )
        } else {
          return (
            <React.Fragment>
              <CssBaseline />
              <Container component="main" maxWidth="sm" className={classes.paper}>
                <Modal
                  open={this.state.modalOpened}
                  onClose={() => this.setState({modalOpened: false})}
                  className={classes.modal}
                >
                  <div className={classes.confirmRefusal}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.refuseButton}
                      disabled={this.state.refuseLoading}
                      onClick={this._refuseDemand}
                    >
                      Êtes vous certain?{this.state.refuseLoading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                    </Button>
                  </div>
                </Modal>
                <div>
                  <Box className={classes.marginTop}>
                    <Typography variant="h5">
                      Demande d'accès pour la catégorie
                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classes.smallMarginTop}>
                      {this.state.demand.category['categoryName']}
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Typography variant="h5">
                      Demandeur
                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classes.smallMarginTop}>
                      {`${this.state.demand.user.contactInfo['fullName']} (${this.state.demand.user.email})`}
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Typography variant="h5">
                      Titre et Organisme (Département)
                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classes.smallMarginTop}>
                      {`${this.state.demand.user.contactInfo['title']} chez ${this.state.demand.user.contactInfo['organismName']} (${this.state.demand.user.contactInfo['department']})`}
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Typography variant="h5">
                      Coordonnées
                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classes.smallMarginTop}>
                      {`${this.state.demand.user.contactInfo['fullAddress']}`}
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Typography variant="h5">
                      Description de l'utilisation des données
                    </Typography>
                    <Typography variant="h6" color="textSecondary" className={classes.smallMarginTop} style={{whiteSpace: 'pre-wrap'}}>
                      {`${this.state.demand.description}`}
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.privateAccess}
                          onChange={e => this.setState({
                            privateAccess: e.target.checked
                          })}
                          name="privateAccess"
                          color="primary"
                        />
                      }
                      label="Permettre l'accès aux dépôts privés"
                    />
                  </Box>
                  <Box className={classes.marginTop}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isPayingContract}
                          onChange={e => this.setState({
                            isPayingContract: e.target.checked
                          })}
                          name="isPayingContract"
                          color="primary"
                        />
                      }
                      label="Cette demande est liée à un contrat d'accès payant"
                    />
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.loading}
                    onClick={this._approveDemand}
                  >
                    Accepter la demande{this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={this.state.refuseLoading}
                    onClick={() => this.setState({modalOpened: true})}
                  >
                    Refuser la demande
                  </Button>
                </div>
              </Container>
            </React.Fragment>
          )
        }
      }
    }
  }
}

export default withStyles(styles)(AdminDemandForm);