import React, { Component } from 'react';
import { 
  Link as RouterLink
} from 'react-router-dom';
import axios from 'axios';

import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  root: {
    maxWidth: 1000
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inlineRight: {
    width: '20%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center'
    }
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  marginTop: {
    marginTop: 12
  },
  spinner: {
    marginLeft: theme.spacing(1)
  }
})

class AdminDemandCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      loading: false
    };

    this._resendNotification = this._resendNotification.bind(this)
  }

  _resendNotification() {
    axios.post('/api/admin/resendNotification', {demandId: this.props.demandId}).then((response) => {
      if (response.data) {
        console.log('Email was sent');
      } else {
        console.log('An error occured');
      }
    })
  }

  formatDate(date){
    return new Date(date).toLocaleDateString("fr-ca", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader
          title={`Demande de ${(this.props.user.contactInfo && this.props.user.contactInfo.fullName) || this.props.user.email} (${this.props.user.email}) pour la catégorie ${this.props.category}`}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="div">
            <div>Description de la demande</div>
            <div>{this.props.description}</div>
          </Typography>
          <Typography className={classes.marginTop} variant="body2" color="textSecondary" component="div">
            <div>Statut de la demande</div>
            <div>{this.props.contractAcceptance && !this.props.isRefused ? "Fermée - Contrat accepté par l'usager" : (this.props.isRefused) ? "Demande refusée" : this.props.isApproved ? `En attente de l'approbation ${this.props.awaitingUserApproval ? "du contrat par l'usager" : `de ${this.props.onDeck}`}` : `En attente d'approbation de ${this.props.approver}`}</div>
          </Typography>
          {this.props.date && <Typography className={classes.marginTop} variant="body2" color="textSecondary" component="div">
            <div>Recu le {this.formatDate(this.props.date)}</div>
          </Typography>}
          {(this.props.onDeck || this.props.awaitingUserApproval) && 
            <Typography className={classes.marginTop} variant="body2" color="textSecondary" component="div">
              {this.props.contractAcceptance ? <div>
                <div>Contrat accepté par l'utilisateur le</div>
                <div>{this.props.contractAcceptance.toLocaleString(undefined, {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})}</div>
              </div> : <div>
                <div>Dernière notification envoyée le</div>
                <div>{this.props.lastApproval.toLocaleString(undefined, {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})}</div>
              </div>}
            </Typography>
          }
        </CardContent>
        <CardActions disableSpacing style={{ width: '100%', justifyContent: 'flex-end' }}>
          {this.props.closed ? null : this.props.isApproved ? 
            <Button 
              onClick={this._resendNotification} 

            >
              Réenvoyer la notification à {this.props.awaitingUserApproval ? this.props.user.email : this.props.onDeck}
            </Button> : <Button
              component={RouterLink}
              to={`/admin/demande/${this.props.demandId}`}
            >
              Traiter la demande
            </Button>}
        </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(AdminDemandCard);