import React, { Component } from "react";

import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import DescriptionIcon from "@material-ui/icons/Description";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import axios from "axios";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200,
  },
  grid: {
    width: 1000,
  },
  inlineTextInput: {
    marginTop: 6,
  },
  grow: {
    flexGrow: 1,
  },
});

class UserFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTermsAndConditions: false,
      loading: null,
      loadPercentage: 0,
    };

    this.getFile = this.getFile.bind(this);
  }

  componentDidMount() {}

  getFile(file) {
    this.setState({ loading: file._id, loadPercentage: 0 });
    axios({
      url: "/api/getFile",
      method: "POST",
      data: {
        fileId: file._id,
      },
      responseType: "blob", // important
      onDownloadProgress: (progress) => {
        this.setState({
          loadPercentage: (progress.loaded / progress.total) * 100,
        });
      },
    })
      .then((response) => {
        this.setState({ loading: null, loadPercentage: 0 });
        if (response.request.response.type == "application/octet-stream") {
          const blob = new Blob([response.data]);
          //const url = window.URL.createObjectURL(blob);
          //const a = document.createElement('a');
          //a.href = url;
          //a.setAttribute('download', file.originalFilename);
          //document.body.appendChild(a);
          //a.click();
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.originalFilename);
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = file.originalFilename;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);
          }
        } else {
          response.data.text().then((textResponse) => {
            const jsonResponse = JSON.parse(textResponse);
            if (jsonResponse.reason == "invalid_session") {
              this.props.handleExpiredSession();
            } else {
              console.log(jsonResponse);
            }
          });
        }
      })
      .catch((e) => console.log("Une erreur s'est produite"));
  }

  showTermsOrGetFile() {
    if (!localStorage.getItem("termsAndConditions")) {
      this.setState({ openTermsAndConditions: true });
    } else {
      this.getFile(this.props.file);
    }
  }

  handleClose() {
    this.setState({ openTermsAndConditions: false });
  }

  handleAccept() {
    this.handleClose();
    this.getFile(this.props.file);
  }

  prettySize(bytes, separator = "", postFix = "") {
    if (bytes) {
      const sizes = ["Octets", "Ko", "Mo", "Go", "To"];
      const i = Math.min(
        parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
        sizes.length - 1
      );
      return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
        sizes[i]
      }${postFix}`;
    }
    return "n/a";
  }

  render() {
    const { classes } = this.props;
    const { openTermsAndConditions } = this.state;
    return (
      <React.Fragment>
        <TermsAndConditionsDialog
          open={openTermsAndConditions}
          handleAccept={this.handleAccept.bind(this)}
          handleClose={this.handleClose.bind(this)}
        />
        <CssBaseline />
        <TableRow key={this.props.file._id}>
          <TableCell>
            <DescriptionIcon className={classes.inlineTextInput} />
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {this.props.file.fileDescription}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {this.prettySize(this.props.file.fileSize, " ")}
          </TableCell>
          <TableCell align="right">
            {this.props.file.lastUpdatedOn &&
              new Date(this.props.file.lastUpdatedOn).toLocaleString()}
          </TableCell>
          <TableCell align="right">
            <Button
              size="large"
              variant="outlined"
              onClick={() => this.showTermsOrGetFile()}
            >
              {this.state.loading == this.props.file._id ? (
                <CircularProgress
                  size={24}
                  variant="static"
                  value={parseInt(this.state.loadPercentage)}
                />
              ) : (
                <CloudDownloadIcon />
              )}
            </Button>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserFile);
