import "./app.css";

import axios from "axios";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { Route, Router, NavLink as RouterLink, Switch } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import MenuIcon from "@material-ui/icons/Menu";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccessForm from "./AccessForm";
import ActivateAccount from "./ActivateAccount";
import AdminChannelForm from "./AdminChannelForm";
import AdminDashboard from "./AdminDashboard";
import AdminDemandForm from "./AdminDemandForm";
import ArtmLogo from "./ArtmLogo";
import CategoryList from "./CategoryList";
import ChannelList from "./ChannelList";
import Faq from "./Faq";
import Home from "./Home";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";
import Signup from "./Signup";
import UserDashboard from "./UserDashboard";

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00B1AC",
      main: "#00B1AC",
      dark: "#008E84",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f7a7a6",
      main: "#f27072",
      dark: "#f27072",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.modal + 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  margin: {
    marginRight: 12,
    textDecoration: "none",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerContainer: {
    overlow: "auto",
  },
  drawerPaper: {
    width: 240,
  },
  appBarSpacer: {
    marginTop: theme.spacing(12),
  },
  spinner: {
    marginTop: theme.spacing(18),
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appLoaded: false,
      user: null,
      userContactInfo: null,
      demands: [],
      subscribedTo: [],
      authorizedFor: [],
      channels: [],
      isAdmin: false,
      anchorEl: null,
      setAnchorEl: null,
      mobileMoreAnchorEl: null,
      setMobileMoreAnchorEl: null,
      isMobileMenuOpen: false,
      isMenuOpen: false,
    };

    this._setUser = this._setUser.bind(this);
    this._setSubscriptions = this._setSubscriptions.bind(this);
    this._handleExpiredSession = this._handleExpiredSession.bind(this);
    this._refreshChannels = this._refreshChannels.bind(this);
    this._logout = this._logout.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData() {
    const userFetch = axios.post("/api/fetchUser", {});
    const channelFetch = axios.post("/api/fetchChannels", {});
    Promise.all([userFetch, channelFetch]).then((responses) => {
      const user = responses[0];
      const channels = responses[1];
      this.setState({
        user: user.data.email ? user.data.email : null,
        userContactInfo: user.data.contactInfo ? user.data.contactInfo : null,
        subscribedTo: user.data.subscribedTo ? user.data.subscribedTo : [],
        demands: user.data.demands ? user.data.demands : [],
        authorizedFor: user.data.authorizedFor ? user.data.authorizedFor : [],
        channels: channels.data,
        isAdmin: user.data.isAdmin,
        appLoaded: true,
        mobileMenu: false,
      });
    });
  }

  _setUser(obj) {
    this.setState(obj);
  }

  _setSubscriptions(obj) {
    this.setState({ subscriptions: obj });
  }

  _refreshChannels() {
    axios
      .post("/api/fetchChannels", {})
      .then((c) => this.setState({ channels: c.data }));
  }

  handleMobileMenuClose() {}

  _handleExpiredSession() {
    this.setState(
      {
        user: null,
        subscribedTo: [],
        demands: [],
        authorizedFor: [],
      },
      () => {
        history.push("/seConnecter");
      }
    );
  }

  _logout() {
    axios.post("/api/logout", {}).then((res) => {
      res.data
        ? this.setState({ user: null, admin: false }, () => history.push("/"))
        : null;
    });
  }

  render() {
    const { anchorEl, setAnchorEl, mobileMoreAnchorEl, setMobileMoreAnchorEl } =
      this.state;
    const { classes } = this.props;
    const mobileMenuId = "primary-search-account-menu-mobile";
    return (
      <Router history={history}>
        <div>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            {!this.state.appLoaded ? (
              <div>
                <AppBar
                  color="primary"
                  position="fixed"
                  className={classes.appBar}
                >
                  <Toolbar>
                    <Box className={classes.grow}>
                      <RouterLink color="inherit" to="/">
                        <Typography variant="h6">
                          <ArtmLogo />
                        </Typography>
                      </RouterLink>
                    </Box>
                  </Toolbar>
                </AppBar>
                <Grid container justify="center">
                  <CircularProgress className={classes.spinner} size={48} />
                </Grid>
                <Box mt={8}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"© "}
                    {new Date().getFullYear()}{" "}
                    <Link color="inherit" href="https://artm.quebec/">
                      Autorité régionale de transport métropolitain | ARTM
                    </Link>{"       "}
                    <Link color="inherit" href="https://www.artm.quebec/enonce-confidentialite/">
                      Énoncé de confidentialité
                    </Link>
                  </Typography>
                </Box>
              </div>
            ) : (
              <div>
                <AppBar
                  color="primary"
                  position="fixed"
                  className={classes.appBar}
                >
                  <Toolbar>
                    <Box>
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Ouvrir le menu"
                        onClick={() =>
                          this.setState((prevState) => ({
                            mobileMenu: !prevState.mobileMenu,
                          }))
                        }
                      >
                        <MenuIcon />
                      </IconButton>
                    </Box>
                    <Box className={classes.grow}>
                      <RouterLink color="inherit" to="/">
                        <Typography variant="h6">
                          <ArtmLogo />
                        </Typography>
                      </RouterLink>
                    </Box>
                    <Box className={classes.sectionDesktop}>
                      {this.state.user && (
                        <Typography
                          className={classes.margin}
                          variant="h6"
                          color="inherit"
                          component={RouterLink}
                          activeStyle={{ fontWeight: "bold" }}
                          to="/abonnements"
                        >
                          Mes abonnements et demandes
                        </Typography>
                      )}
                      <Typography
                        className={classes.margin}
                        variant="h6"
                        color="inherit"
                        component={RouterLink}
                        activeStyle={{ fontWeight: "bold" }}
                        to="/depots"
                      >
                        Liste des dépôts
                      </Typography>
                      <Typography
                        className={classes.margin}
                        variant="h6"
                        color="inherit"
                        component={RouterLink}
                        activeStyle={{ fontWeight: "bold" }}
                        to="/faq"
                      >
                        FAQ
                      </Typography>
                      {this.state.user && this.state.isAdmin ? (
                        <Typography
                          className={classes.margin}
                          variant="h6"
                          color="inherit"
                          component={RouterLink}
                          activeStyle={{ fontWeight: "bold" }}
                          to="/admin"
                        >
                          Admin
                        </Typography>
                      ) : null}
                      {!this.state.user ? (
                        <Typography
                          className={classes.margin}
                          variant="h6"
                          color="inherit"
                          component={RouterLink}
                          activeStyle={{ fontWeight: "bold" }}
                          to="/seConnecter"
                        >
                          Se connecter
                        </Typography>
                      ) : null}
                    </Box>
                    <Box>
                      {!!this.state.user ? (
                        <IconButton
                          className={classes.sectionDesktop}
                          color="inherit"
                          aria-label="Se déconnecter"
                          onClick={this._logout}
                        >
                          <ExitToAppIcon />
                        </IconButton>
                      ) : null}
                    </Box>
                  </Toolbar>
                </AppBar>
                <Drawer
                  className={classes.drawer}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  anchor={"left"}
                  open={this.state.mobileMenu}
                  onClose={() => this.setState({ mobileMenu: false })}
                >
                  <div className={classes.drawerContainer}>
                    <Toolbar color="primary" />
                    <List>
                      {this.state.user && (
                        <ListItem
                          button
                          key="mmDashbaord"
                          component={RouterLink}
                          to="/abonnements"
                        >
                          <ListItemIcon>
                            <HomeIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Mes abonnements et demandes"}
                          />
                        </ListItem>
                      )}
                      <ListItem
                        button
                        key="mmFaq"
                        component={RouterLink}
                        to="/faq"
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={"FAQ"} />
                      </ListItem>
                      <ListItem
                        button
                        key="mmChannelList"
                        component={RouterLink}
                        to="/depots"
                      >
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Liste des dépôts"} />
                      </ListItem>
                      {this.state.user && this.state.isAdmin && (
                        <ListItem
                          button
                          key="mmAdmin"
                          component={RouterLink}
                          to="/admin"
                        >
                          <ListItemIcon>
                            <SupervisorAccountIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Admin"} />
                        </ListItem>
                      )}
                      {!this.state.user && (
                        <ListItem
                          button
                          key="mmLogin"
                          component={RouterLink}
                          to="/seConnecter"
                        >
                          <ListItemIcon>
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Se connecter"} />
                        </ListItem>
                      )}
                      {this.state.user && (
                        <ListItem button key="mmLogout" onClick={this._logout}>
                          <ListItemIcon>
                            <ExitToAppIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Se déconnecter"} />
                        </ListItem>
                      )}
                    </List>
                  </div>
                </Drawer>
                <div className={classes.appBarSpacer}>
                  <Switch>
                    <Route
                      path="/seConnecter"
                      render={(props) => (
                        <Login
                          {...props}
                          setUser={this._setUser}
                          user={!!this.state.user}
                        />
                      )}
                    />
                    <Route
                      path="/creerUnCompte"
                      render={(props) => (
                        <Signup {...props} user={!!this.state.user} />
                      )}
                    />
                    <Route path="/faq" component={Faq} />
                    <Route
                      path="/depots/:categoryId"
                      render={(props) => (
                        <ChannelList
                          {...props}
                          handleExpiredSession={this._handleExpiredSession}
                          appLoaded={this.state.appLoaded}
                          isAuthenticated={!!this.state.user}
                          userContactInfo={this.state.userContactInfo}
                          user={this.state.user}
                        />
                      )}
                    />
                    <Route
                      path="/depots"
                      render={(props) => <CategoryList {...props} />}
                    />
                    <Route
                      exact
                      path="/activerCompte/:token"
                      render={(props) => <ActivateAccount {...props} />}
                    />
                    <Route
                      path="/recupererMotDePasse"
                      render={(props) => <RecoverPassword {...props} />}
                    />
                    <Route
                      exact
                      path="/reinitialiserMotDePasse/:token"
                      render={(props) => <ResetPassword {...props} />}
                    />
                    <PrivateRoute
                      exact
                      path="/nouvelleDemande/:channelId"
                      handleExpiredSession={this._handleExpiredSession}
                      isAuthenticated={!!this.state.user}
                      user={this.state.user}
                      appLoaded={this.state.appLoaded}
                      component={AccessForm}
                    />
                    <PrivateRoute
                      path="/abonnements"
                      handleExpiredSession={this._handleExpiredSession}
                      isAuthenticated={!!this.state.user}
                      user={this.state.user}
                      appLoaded={this.state.appLoaded}
                      component={UserDashboard}
                      userContactInfo={this.state.userContactInfo}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/demande/:id"
                      handleExpiredSession={this._handleExpiredSession}
                      isAuthenticated={
                        !!this.state.user && !!this.state.isAdmin
                      }
                      user={this.state.user}
                      appLoaded={this.state.appLoaded}
                      component={AdminDemandForm}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/depot/:id"
                      handleExpiredSession={this._handleExpiredSession}
                      isAuthenticated={
                        !!this.state.user && !!this.state.isAdmin
                      }
                      user={this.state.user}
                      appLoaded={this.state.appLoaded}
                      component={AdminChannelForm}
                    />
                    <PrivateRoute
                      path="/admin"
                      handleExpiredSession={this._handleExpiredSession}
                      isAuthenticated={
                        !!this.state.user && !!this.state.isAdmin
                      }
                      user={this.state.user}
                      appLoaded={this.state.appLoaded}
                      component={AdminDashboard}
                    />
                    <Route path="/" component={Home} />
                  </Switch>
                </div>
                <Box mt={8}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"© "}
                    {new Date().getFullYear()}{" "}
                    <Link color="inherit" href="https://artm.quebec/">
                      Autorité régionale de transport métropolitain | ARTM
                    </Link> &nbsp;&nbsp;&nbsp;
                    <Link color="primary" href="https://www.artm.quebec/enonce-confidentialite/">
                      Énoncé de confidentialité
                    </Link>  
                  </Typography>
                </Box>
              </div>
            )}
          </ThemeProvider>
        </div>
      </Router>
    );
  }
}

export default withStyles(useStyles)(App);
