import React, { Component } from 'react';
import {
  Link as RouterLink
} from 'react-router-dom';
import axios from 'axios';

import UserFile from '../UserFile';

import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Link from '@material-ui/core/Link';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  root: {
    maxWidth: 1000
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inlineRight: {
    width: '20%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center'
    }
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  spinner: {
    marginLeft: theme.spacing(1)
  }
})

class ChannelCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      loading: false
    };
    this._addSubscription = this._addSubscription.bind(this);
    this._removeSubscription = this._removeSubscription.bind(this);
  }

  _addSubscription() {
    this.setState({loading: true})
    axios.post('/api/addSubscription', {channelId: this.props._id}).then((res) => {
      if (res.data.success) {
        this.setState({loading: false})
        this.props.refreshData({
          demands: res.data.demands,
          subscribedTo: res.data.subscribedTo,
          authorizedFor: res.data.authorizedFor
        });
      } else if (!res.data.success && res.data.reason == "invalid_session") {
        this.setState({loading: false})
        this.props.handleExpiredSession();
      } else {
        this.setState({loading: false,errorMessage: "Une erreur est survenue"})
      }
    })
  }

  _removeSubscription() {
    this.setState({loading: true})
    axios.post('/api/removeSubscription', {channelId: this.props._id}).then((res) => {
      if (res.data.success) {
        this.setState({loading: false})
        this.props.refreshData({
          demands: res.data.demands,
          subscribedTo: res.data.subscribedTo,
          authorizedFor: res.data.authorizedFor
        });
      } else if (!res.data.success && res.data.reason == "invalid_session") {
        this.setState({loading: false})
        this.props.handleExpiredSession();
      } else {
        this.setState({loading: false, errorMessage: "Une erreur est survenue"})
      }
    })
  }

  render() {
    /*
      Existing status
        #Free access -> button always available
        On demand only
          #->If authorized, button
          ->Else
            ->If demand is in process, then "awaiting"
            ->If demand is completed and contract not signed, then awaiting signature
            ->If no demand
              ->Make a demand



    */
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader
          title={this.props.channelName}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap'}}>
            {this.props.channelDescription}
          </Typography>
        </CardContent>
        <CardActions disableSpacing style={{ width: '100%', justifyContent: 'flex-end' }}>
          { //For open access or authorized user
            (this.props.isOpenAccess || this.props.isAuthorized) && <span>
              <Button
                onClick={() => {this.setState({expanded: !this.state.expanded})}}
              >
                {this.state.expanded ? "Masquer les détails" : "Détails"}
              </Button>
              {this.props.isAuthenticated && (
                this.props.isSubscribed ? <Button
                  onClick={this._removeSubscription}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : "Se désabonner"}
                </Button> : <Button
                  onClick={this._addSubscription}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : "S'abonner"}
                </Button>)
              }
            </span>
          }

          { //For unauthorized channels that require approval and there is no active demands for this category
            (this.props.isAuthenticated && !this.props.isAuthorized && !this.props.isOpenAccess && !this.props.activeDemand) &&
            <Button
              component={RouterLink}
              to={`/nouvelleDemande/${this.props._id}`}
            >
              {"Faire une demande d'accès"}
            </Button>
          }

          { //For non authenticated user and channel that are not open access}
            (!this.props.isAuthenticated && !this.props.isOpenAccess) && <Typography style={{textTransform: 'uppercase'}} variant="body2">Vous devez <Link component={RouterLink} to="/seConnecter">vous connecter</Link> ou <Link component={RouterLink} to="/creerUnCompte">créer un compte</Link> un compte pour accéder à ce dépôt</Typography>
          }

          { //For a pending demand
            (!this.props.isAuthorized && !this.props.isOpenAccess && this.props.activeDemand && !this.props.awaitingContractAcceptance) && "En attente d'approbation"
          }

          { //For a demand awaiting a signature
            (!this.props.isAuthorized && !this.props.isOpenAccess && this.props.awaitingContractAcceptance) && "En attente de signature"
          }

        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.files && <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Liste des fichiers">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nom du fichier</TableCell>
                  <TableCell align="right">Taille</TableCell>
                  <TableCell align="right">Dernière mise à jour</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.files.map((f) => (<UserFile key={`f_${f._id}`} file={f} handleExpiredSession={this.props.handleExpiredSession} />))}
              </TableBody>
            </Table>
          </TableContainer>}
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}




export default withStyles(styles)(ChannelCard);