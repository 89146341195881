import React, { Component } from "react";
import axios from "axios";
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DemandCard from "./cards/DemandCard";
import ChannelCard from "./cards/ChannelCard";

import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200,
  },
  grid: {
    width: 1000,
  },
  spinner: {
    marginTop: theme.spacing(6),
  },
});

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      subscribedTo: [],
      demands: [],
      authorizedFor: [],
      loading: true,
    };
    this._fetchData = this._fetchData.bind(this);
    this._setSubscriptions = this._setSubscriptions.bind(this);
  }

  _fetchData() {
    const channelQuery = axios.post("/api/fetchChannels", {});
    const subscriptionsQuery = axios.post("/api/fetchSubscriptions", {});
    Promise.all([channelQuery, subscriptionsQuery]).then((responses) => {
      this.setState({
        loading: false,
        channels: responses[0].data,
        ...(responses[1].data
          ? responses[1].data
          : { fetchError: true, error: "Error message to be implemented" }),
      });
    });
  }

  _setSubscriptions(res) {
    this.setState({
      subscribedTo: res.subscribedTo,
      demands: res.demands,
      authorizedFor: res.authorizedFor,
    });
  }

  componentDidMount() {
    //Test to change the way this is handled by fetching everything this page needs on load
    this._fetchData();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid container justify="center">
            {this.state.loading ? (
              <CircularProgress className={classes.spinner} size={48} />
            ) : (
              <Grid
                spacing={3}
                alignItems="center"
                justify="center"
                container
                className={classes.grid}
              >
                {!this.state.demands.filter((demand) => !demand.closed).length >
                  0 &&
                  !this.state.subscribedTo.length > 0 && (
                    <React.Fragment>
                      <Typography>{`Vos abonnements et le statut de vos demandes apparaîtront sur cette page.`}</Typography>
                      <Typography>
                        {`Vous pouvez accéder aux dépôts publics, ajouter des abonnements ou faire des demandes en visitant la `}
                        <Typography
                          color="inherit"
                          component={RouterLink}
                          to="/depots"
                        >
                          liste des dépôts
                        </Typography>
                      </Typography>
                    </React.Fragment>
                  )}
                {this.state.demands.filter((demand) => !demand.closed).length >
                0 ? (
                  <Grid item xs={12}>
                    Mes demandes
                  </Grid>
                ) : null}
                {this.state.demands
                  .filter((demand) => !demand.closed)
                  .map((demand) => (
                    <Grid item xs={12} key={`grid_${demand._id}`}>
                      <DemandCard
                        {...demand}
                        handleExpiredSession={this.props.handleExpiredSession}
                        cardHeader={demand.category.categoryName}
                        key={demand._id}
                        user={this.props.user}
                        refreshData={this._setSubscriptions}
                        userContactInfo={this.props.userContactInfo}
                        lastSigningAdmin={
                          demand.approvalsBy.length > 0 &&
                          demand.approvalsBy.filter((a) => a.user)[
                            demand.approvalsBy.filter((a) => a.user).length - 1
                          ].user
                        }
                        lastSigningOn={
                          demand.approvalsBy.length > 0 &&
                          demand.approvalsBy[demand.approvalsBy.length - 1].date
                        }
                        awaitingSignature={
                          this.state["authorizedFor"] &&
                          !!this.state["authorizedFor"].find(
                            (a) => a.category == demand.category._id
                          ) &&
                          demand.approved &&
                          !demand.signature
                        }
                      />
                    </Grid>
                  ))}
                {this.state.subscribedTo.length > 0 ? (
                  <Grid item xs={12}>
                    Mes abonnements
                  </Grid>
                ) : null}
                {this.state.subscribedTo.map((channel) => (
                  <Grid item xs={12} key={`grid_${channel._id}`}>
                    <ChannelCard
                      {...channel}
                      key={channel._id}
                      files={
                        this.state.channels.find((c) => c._id == channel._id)
                          ? this.state.channels.find(
                              (c) => c._id == channel._id
                            )["files"]
                          : [{}]
                      }
                      refreshData={this._setSubscriptions}
                      handleExpiredSession={this.props.handleExpiredSession}
                      isAuthorized={
                        this.state.authorizedFor.filter(
                          (auth) =>
                            auth.category == channel.category && auth.active
                        ).length > 0
                      }
                      awaitingContractAcceptance={
                        this.state.authorizedFor.filter(
                          (auth) =>
                            auth.category == channel.category && !auth.active
                        ).length > 0
                      }
                      activeDemand={
                        this.state.demands.filter(
                          (demand) =>
                            demand.category._id == channel.category &&
                            !demand.signature
                        ).length > 0
                      }
                      isSubscribed={
                        !!this.state.subscribedTo.find(
                          (c) => c["_id"] == channel["_id"]
                        )
                      }
                      showSubscribeButton={
                        !this.state.subscribedTo.find(
                          (c) => c["_id"] == channel["_id"]
                        )
                      }
                      showExpandButton={
                        !!this.state.subscribedTo.find(
                          (c) => c["_id"] == channel["_id"]
                        )
                      }
                      isAuthenticated={this.props.isAuthenticated}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserDashboard);
