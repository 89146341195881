import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';

import { 
  Link as RouterLink
} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const styles = theme => ({
  root: {
    maxWidth: 1000
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inlineRight: {
    width: '20%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center'
    }
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  contractParagraph: {
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(1)
  },
  spinner: {
    marginLeft: theme.spacing(1)
  }
})

class DemandCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      signature: "",
      loading: false
    };

    this._acceptContract = this._acceptContract.bind(this);
  }

  _acceptContract(event) {
    event.preventDefault();
    this.setState({loading: true});
    axios.post('/api/acceptContract', {
      demandId: this.props._id, 
      signature: this.state.signature
    }).then((res) => {
      this.setState({loading: false});
      if (res.data.success) {
        this.props.refreshData({
          demands: res.data.demands,
          subscribedTo: res.data.subscribedTo,
          authorizedFor: res.data.authorizedFor
        });
      } else if (!res.data.success && res.data.reason == 'invalid_session') {
        this.props.handleExpiredSession();
      } else {
        this.setState({errorMessage: "Une erreur est survenue"})
      }

    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Card className={classes.root}>
          <CardHeader
            title={this.props.cardHeader}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.props.channelDescription}
            </Typography>
          </CardContent>
          <CardActions disableSpacing style={{ width: '100%', justifyContent: 'flex-end' }}>
            {this.props.awaitingSignature ? <Button onClick={() => this.setState({expanded: !this.state.expanded})}>{this.state.expanded ? "Masquer le contrat" : "Cliquez ici pour réviser les termes du contrat"}</Button> : "En attente d'approbation"}
          </CardActions>
          {this.props.awaitingSignature && <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <List subheader={<ListSubheader>Contrat</ListSubheader>}>
                <Paper style={{maxHeight: 400, overflowY: 'scroll', padding: 8}}
                >
<Typography variant="h5">{`LICENCE D’UTILISATION DU FICHIER DE DONNÉES DE DÉPLACEMENT DE L’${this.props.category['categoryName'] && this.props.category.categoryName.toUpperCase()}`}</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  ENTRE : L’AUTORITÉ RÉGIONALE DE TRANSPORT MÉTROPOLITAIN, personne morale de droit public légalement instituée par la Loi sur l’Autorité régionale de transport métropolitain (RLRQ, c. C-33.3), ayant son siège au 700, de la Gauchetière Ouest, 4e étage à Montréal, Québec, H3B 5M2, représenté par ${this.props.lastSigningAdmin.contactInfo && this.props.lastSigningAdmin.contactInfo.fullName}, ${this.props.lastSigningAdmin.contactInfo && this.props.lastSigningAdmin.contactInfo.title} – ${this.props.lastSigningAdmin.contactInfo && this.props.lastSigningAdmin.contactInfo.department}, dûment autorisé à intervenir aux fins des présentes ;

ci-après appelée le « FOURNISSEUR »`}</Typography>


<Typography variant="body2" className={classes.contractParagraph}>{`  ET : ${this.props.userContactInfo && this.props.userContactInfo.organismName}, ${this.props.userContactInfo && this.props.userContactInfo.department}, personne morale dûment constituée, ayant des bureaux au ${this.props.userContactInfo && this.props.userContactInfo.fullAddress}, représenté(e) par ${this.props.userContactInfo && this.props.userContactInfo.fullName}, ${this.props.userContactInfo && this.props.userContactInfo.title}, dûment autorisé à intervenir aux fins des présentes ;
 
ci-après appelé « l’UTILISATEUR »`}</Typography>






<Typography variant="h6">1.  LICENCE</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  Par la présente convention, le FOURNISSEUR concède à l’UTILISATEUR une licence incessible, non exclusive et perpétuelle sur le « Fichier de données de déplacement de l’${this.props.category['categoryName'] && this.props.category.categoryName} ».`}</Typography>


<Typography variant="h6">2.  PRODUIT</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  Le « Fichier de données de déplacement de l’${this.props.category['categoryName'] && this.props.category.categoryName} » (ci-après « le PRODUIT ») décrit l’ensemble des déplacements de personnes de manière désagrégée pour la région de Montréal, issus de l’${this.props.category['categoryName'] && this.props.category.categoryName}, où l’information sur les extrémités de déplacement est fournie selon le découpage des secteurs/subdivisions de recensement de Statistique Canada et selon les coordonnées géographiques XY au format MTM Nad 83. Les informations contenues au PRODUIT sont décrites en annexe de la présente convention.`}</Typography>

<Typography variant="h6">3.  UTILISATION</Typography>

<Typography variant="body2" className={classes.contractParagraph}>
{`  L’UTILISATEUR peut exploiter le PRODUIT pour ses propres fins d’information et d’analyse seulement. Il peut produire tout traitement de données à partir du PRODUIT et en publier tout résultat, pour autant que la source des données soit indiquée dans ladite publication, et ce, de la manière suivante :

    [Source : ${this.props.category['categoryName'] && this.props.category.categoryName} de la région de Montréal version XX.YY]
    [Traitement : « nom de l’UTILISATEUR »]

  La duplication du PRODUIT est permise à des fins de sauvegarde seulement. En aucun cas, le PRODUIT ne peut être installé et exploité simultanément sur deux supports physiques distincts.

  Tout consultant externe ou organisme de recherche dont les services sont retenus par l’UTILISATEUR et qui, dans le cadre de son mandat, a accès au PRODUIT, doit s’engager formellement à respecter la présente convention, sous peine que l’UTILISATEUR soit considéré en défaut en vertu des présentes.`}</Typography>

<Typography variant="h6">4.  PROPRIÉTÉ</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  L’UTILISATEUR n’acquiert aucun droit de propriété sur le PRODUIT ou la documentation connexe fournie. Tout droit de propriété intellectuelle et tout droit d’auteur sur le PRODUIT et sa documentation appartiennent exclusivement à l’ARTM

  Les droits de propriété s’appliquent également à toute reproduction de données ou de textes constituant le PRODUIT, y compris toute version modifiée ou traduite, de même que toute copie sur support électronique, informatique ou télématique de ces données ou textes.`}</Typography>


<Typography variant="h6">5.  SÉCURITÉ</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  L’UTILISATEUR s’engage à respecter et à protéger les droits de propriété sur le PRODUIT. Il prend toutes les mesures normalement requises pour que ses employés, associés, affiliés, mandataires ou clients ainsi que toute personne ayant accès à ses installations ne puissent, à d’autres fins, utiliser ou copier, sans autorisation, une quelconque partie du PRODUIT.`}</Typography>


<Typography variant="h6">6.  RESPONSABILITÉ</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  Le FOURNISSEUR n’assure aucun service de soutien technique quant à l’utilisation du PRODUIT.

  L’UTILISATEUR reconnaît que le PRODUIT a été élaboré à partir de fichiers fondamentaux et que, même si tous les moyens raisonnables ont été pris pour assurer la qualité des données contenues au PRODUIT, il est possible que des erreurs aient pu se glisser à une étape ou l’autre de la préparation du fichier. L’UTILISATEUR accepte qu’aucune garantie quelle qu’elle soit, explicite ou implicite, n’est donnée par le FOURNISSEUR relativement au PRODUIT.

  L’UTILISATEUR dégage le FOURNISSEUR de toute responsabilité pour tout dommage ou conséquence découlant de l’utilisation du PRODUIT. L’UTILISATEUR est responsable de vérifier la pertinence et la logique de tous les calculs et traitements qu’il effectue et assume l’entière responsabilité des résultats et analyses qui en découlent.

  L’UTILISATEUR s’engage à faire part au FOURNISSEUR de tout constat d’invalidité ou d’erreur découverte dans les données contenues au PRODUIT afin que des mesures correctives, le cas échéant, soient prises sans tarder.`}</Typography>


<Typography variant="h6">7.  RÉVISIONS</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  Le FOURNISSEUR s’engage à fournir sans frais à l’UTILISATEUR tout fichier de révision ou de mise à jour s’appliquant au PRODUIT et qu’il déciderait de préparer et diffuser.

  L’UTILISATEUR est libre, le cas échéant, d’utiliser ou non le fichier révisé dont il dispose. Dans tous les cas, les résultats publiés doivent signaler, au niveau de la source des données, le numéro de la version du fichier utilisé.`}</Typography>


<Typography variant="h6">8.  DURÉE</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  La présente convention prend effet au moment de sa signature par les parties et reste en vigueur tant et aussi longtemps que le PRODUIT n’est pas retourné au FOURNISSEUR, à moins qu’il n’y soit mis fin en vertu de l’article 9, ci-après.`}</Typography>





<Typography variant="h6">9.  DÉFAUT</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  Le FOURNISSEUR peut mettre fin à la présente convention pour les raisons suivantes :

  1. Exploitation par l’UTILISATEUR du PRODUIT d’une façon non autorisée par la présente convention ;
  2. Défaut de l’UTILISATEUR ou de tout tiers autorisé par lui à utiliser le PRODUIT de prendre les mesures raisonnables pour protéger les droits de propriété du FOURNISSEUR;
  3.  Tout changement dans la mission de l’UTILISATEUR qui fait en sorte que son utilisation du PRODUIT n’est plus requise;
  4.  Insolvabilité ou faillite de l’UTILISATEUR. 

  Si l’UTILISATEUR est en défaut tel que susdit, le FOURNISSEUR lui fera parvenir un avis écrit, l’avisant d’y remédier dans un délai raisonnable compte tenu de la nature du défaut reproché.

  Si l’UTILISATEUR ne remédie pas au défaut dans le délai imparti, le FOURNISSEUR peut résilier la convention. Dans un tel cas, l’UTILISATEUR doit restituer le PRODUIT ainsi que toute documentation fournie avec le PRODUIT, en détruire toute copie qu’il pourrait avoir en sa possession et fournir la preuve de telle destruction à la demande du FOURNISSEUR.`}</Typography>


<Typography variant="h6">10. REDEVANCE</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`  En considération de la présente licence d’utilisation, le coût de cette licence est nul.`}</Typography>


<Typography variant="h6">11. DOMICILE ET LOIS APPLICABLES</Typography>
  
<Typography variant="body2" className={classes.contractParagraph}>{`  La présente licence est régie par le droit applicable au Québec. Les parties élisent domicile dans le district de Montréal.`}</Typography>

<Typography variant="body2" className={classes.contractParagraph}>{`
EN FOI DE QUOI, LES PARTIES ONT SIGNÉ ÉLECTRONIQUEMENT
AUTORITÉ RÉGIONALE DE TRANSPORT MÉTROPOLITAIN

${this.props.lastSigningAdmin.contactInfo ? this.props.lastSigningAdmin.contactInfo.fullName : this.props.lastSigningAdmin.email} Le ${(new Date(this.props.lastSigningOn && this.props.lastSigningOn)).toLocaleString()}
Courriel : ${this.props.lastSigningAdmin.email}
${this.props.lastSigningAdmin.contactInfo ? this.props.lastSigningAdmin.contactInfo.title : this.props.lastSigningAdmin.email}
${this.props.lastSigningAdmin.contactInfo ? this.props.lastSigningAdmin.contactInfo.department : this.props.lastSigningAdmin.email}


${this.props.userContactInfo && this.props.userContactInfo.organismName} – ${this.props.userContactInfo && this.props.userContactInfo.department}.
Courriel: ${this.props.user}
${this.state.signature == "" ? "______________" : this.state.signature} Le ${(new Date).toLocaleString()}
${this.props.userContactInfo && this.props.userContactInfo.title}
`}</Typography>




                </Paper>
                <ValidatorForm 
                  onError={errors => console.log('errors', errors)}
                  onSubmit={this._acceptContract}
                >
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="signature"
                    label="Votre signature"
                    name="signature"
                    value={this.state.signature}
                    onChange={e => this.setState({signature: e.target.value})}
                    validators={['required']}
                    errorMessages={['Vous devez remplir ce champs']}
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading}
                    className={classes.submit}
                  >
                    Accepter les termes du contrat{this.state.loading ? <CircularProgress className={classes.spinner} size={16} /> : null}
                  </Button>
                </ValidatorForm>
              </List>
            </CardContent>
          </Collapse>}
        </Card>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DemandCard);