import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import uniqBy from "lodash/uniqBy";
import CategoryCard from "./cards/CategoryCard";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey.A500,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    padding: 20,
    paddingBottom: 40,
  },
  linkStyle: {
    textDecoration: "none",
  },
  grid: {
    width: 1000,
  },
  topMargin: {
    marginTop: 20,
  },
  spinner: {
    marginTop: theme.spacing(6),
  },
});

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true,
    };
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData() {
    const channelQuery = axios.post("/api/fetchChannels", {});
    const subscriptionsQuery = axios.post("/api/fetchSubscriptions", {});
    Promise.all([channelQuery, subscriptionsQuery]).then((responses) => {
      const [channels] = responses.map((r) => r.data);
      this.setState({
        loading: false,
        categories: uniqBy(
          channels.map((c) => c.category),
          "_id"
        ).sort((a, b) => a.sortOrder - b.sortOrder),
      });
    });
  }

  renderCategories() {
    const { classes } = this.props;
    const { categories } = this.state;
    return (
      <Grid
        spacing={3}
        alignItems="center"
        justify="center"
        container
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.topMargin}>
            Choisissez une catégorie :
          </Typography>
        </Grid>
        {categories.map((category) => (
          <Grid item xs={12} key={`grid_${category._id}`}>
            <NavLink
              to={`/depots/${category._id}`}
              className={classes.linkStyle}
            >
              <CategoryCard
                key={category._id}
                name={category.categoryName}
                description={category.categoryDescription}
              />
            </NavLink>
          </Grid>
        ))}
      </Grid>
    );
  }

  renderGridItems() {
    const { classes } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <CircularProgress className={classes.spinner} size={48} />;
    }
    return this.renderCategories();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid container justify="center">
            {this.renderGridItems()}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CategoryList);
