import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import axios from "axios";
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ChannelCard from "./cards/ChannelCard";
import DemandCard from "./cards/DemandCard";
import CategoryCard from "./cards/CategoryCard";
//import Topbar from "./Topbar";
//import SectionHeader from "./typo/SectionHeader";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Breadcrumbs } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    padding: 20,
    paddingBottom: 40,
  },
  grid: {
    width: 1000,
  },
  topMargin: {
    marginTop: 20,
  },
  title: {
    color: theme.palette.text.primary,
  },
  spinner: {
    marginTop: theme.spacing(6),
  },
});

function shouldShowDemand(channel, demands, categoryId) {
  return (
    channel.requiresApproval &&
    demands.filter(
      (demand) =>
        demand.category._id === categoryId &&
        !demand.signature &&
        !demand.closed
    ).length > 0
  );
}

class ChannelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: {},
      subscribedTo: [],
      demands: [],
      authorizedFor: [],
      loading: true,
    };
  }

  componentDidMount() {
    this._fetchData();
    this._setSubscriptions = this._setSubscriptions.bind(this);
  }

  _fetchData() {
    const { match } = this.props;
    const { categoryId } = match.params;
    const channelQuery = axios.post("/api/fetchChannels", {});
    const subscriptionsQuery = axios.post("/api/fetchSubscriptions", {});
    Promise.all([channelQuery, subscriptionsQuery]).then((responses) => {
      const [channels, subscriptions] = responses.map((r) => r.data);
      this.setState({
        loading: false,
        channels: channels
          .filter((c) => c.category._id === categoryId)
          .sort((a, b) => a.sortOrder - b.sortOrder),
        ...(subscriptions || {
          fetchError: true,
          error: "Error message to be implemented",
        }),
      });
    });
  }

  _setSubscriptions(res) {
    this.setState({
      subscribedTo: res.subscribedTo,
      demands: res.demands,
      authorizedFor: res.authorizedFor,
    });
  }

  renderChannel(channel) {
    const { handleExpiredSession, user, userContactInfo, isAuthenticated } =
      this.props;
    const { demands, subscribedTo, authorizedFor } = this.state;
    const categoryId = channel.category._id;
    const isAuthorized = Boolean(
      authorizedFor.find((auth) => auth.category === categoryId && auth.active)
    );
    const isSubscribed = Boolean(
      subscribedTo.find((c) => c._id === channel._id)
    );
    const demand = demands.find(
      (d) => d.category._id === categoryId && !d.closed
    );
    if (shouldShowDemand(channel, demands, categoryId)) {
      return (
        <Grid item xs={12} key={`grid_${channel._id}`}>
          <DemandCard
            {...demand}
            handleExpiredSession={handleExpiredSession}
            cardHeader={channel.channelName}
            key={demand._id}
            refreshData={this._setSubscriptions}
            user={user}
            lastSigningAdmin={
              demand.approvalsBy.length > 0 &&
              demand.approvalsBy.filter((a) => a.user)[
                demand.approvalsBy.filter((a) => a.user).length - 1
              ].user
            }
            lastSigningOn={
              demand.approvalsBy.length > 0 &&
              demand.approvalsBy[demand.approvalsBy.length - 1].date
            }
            userContactInfo={userContactInfo}
            awaitingSignature={
              authorizedFor &&
              !!authorizedFor.find((a) => a.category === demand.category._id) &&
              demand.approved &&
              !demand.signature
            }
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12} key={`grid_${channel._id}`}>
        <ChannelCard
          {...channel}
          key={channel._id}
          refreshData={this._setSubscriptions}
          handleExpiredSession={handleExpiredSession}
          isAuthorized={isAuthorized}
          awaitingContractAcceptance={isAuthorized}
          activeDemand={Boolean(demand)}
          isSubscribed={isSubscribed}
          showSubscribeButton={!isSubscribed}
          showExpandButton={isSubscribed}
          isAuthenticated={isAuthenticated}
        />
      </Grid>
    );
  }

  renderChannels() {
    const { classes } = this.props;
    const { channels } = this.state;
    return (
      <Grid
        spacing={3}
        alignItems="center"
        justify="center"
        container
        className={classes.grid}
      >
        <Grid item xs={12} className={classes.topMargin}>
          <Breadcrumbs separator="›" aria-label="fil d'ariane">
            <Typography
              variant="h6"
              component={NavLink}
              color="inherit"
              to="/depots"
            >
              Catégories
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {channels[0].category.categoryName}
            </Typography>
          </Breadcrumbs>
        </Grid>
        {channels.map((channel) => this.renderChannel(channel))}
      </Grid>
    );
  }

  renderGridItems() {
    const { classes } = this.props;
    const { selectedCategory, loading } = this.state;
    if (loading) {
      return <CircularProgress className={classes.spinner} size={48} />;
    }
    return this.renderChannels(selectedCategory);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid container justify="center">
            {this.renderGridItems()}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(ChannelList));
