import React, { Component } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Redirect,
} from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import ErrorIcon from "@material-ui/icons/Error";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Recaptcha from "react-recaptcha";
import config from "../../config/client.json";

let recaptchaInstance;

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
});

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      redirectToReferrer: false,
      message: "",
      success: false,
      captchaToken: null,
      loading: false,
    };

    this._recoverPassword = this._recoverPassword.bind(this);
  }

  _recoverPassword(event) {
    event.preventDefault();
    this.setState({ loading: true });
    axios
      .post("/api/recoverPassword", {
        email: this.state.email,
        captchaToken: this.state.captchaToken,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            loading: false,
            success: true,
            message:
              "Un courriel vous sera envoyé avec les indications vous permettant de réinitialiser votre mot de passe",
          });
        } else {
          this.setState({ loading: false, message: "Une erreur est survenue" });
          setTimeout(() => {
            this.setState({ message: "" });
          }, 5000);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {this.state.success ? (
          <div className={classes.paper}>
            {this.state.showSpinner && <CircularProgress />}
            <Typography component="h1" variant="h5" align="center">
              {this.state.message}
            </Typography>
          </div>
        ) : (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <ErrorIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Réinitialiser le mot de passe
            </Typography>
            <ValidatorForm
              className={classes.form}
              onError={(errors) => console.log("errors", errors)}
              onSubmit={this._recoverPassword}
            >
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Courriel"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                validators={["required", "isEmail"]}
                errorMessages={[
                  "Vous devez remplir ce champs",
                  "Vous devez entrer une adresse courriel valide",
                ]}
              />
              <Box display="flex" width="100%">
                <Box m="auto">
                  <Recaptcha
                    sitekey={config.publicCaptchaToken}
                    hl="fr"
                    ref={(e) => (recaptchaInstance = e)}
                    render="explicit"
                    onloadCallback={() => console.log("captcha rendered")}
                    verifyCallback={(response) => {
                      this.setState({ captchaToken: response });
                    }}
                  />
                </Box>
              </Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="submit"
                disabled={!this.state.captchaToken || this.state.loading}
              >
                Réinitialiser le mot de passe
                {this.state.loading ? (
                  <CircularProgress className={classes.spinner} size={16} />
                ) : null}
              </Button>
              {this.state.errorMessage != "" && (
                <Typography component="h1" variant="h5" align="center">
                  {this.state.errorMessage}
                </Typography>
              )}
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/seConnecter"
                    variant="body2"
                    style={{ fontSize: 16 }}
                  >
                    {"J'ai retrouvé mon mot de passe!"}
                  </Link>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(RecoverPassword);
