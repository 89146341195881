import React, { Component } from "react";
import axios from "axios";

import {
  BrowserRouter as Router,
  Redirect,
  NavLink as RouterLink,
} from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import Container from "@material-ui/core/Container";

import CircularProgress from "@material-ui/core/CircularProgress";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
  bigSpinner: {
    marginTop: theme.spacing(6),
  },
});

class AccessForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nbOfLicences: "",
      demandDescription: "",
      redirectToList: false,
      fullName: "",
      organismName: "",
      title: "",
      department: "",
      fullAddress: "",
      errorMessage: null,
      success: false,
      message: null,
      postFormSpinner: false,
      loading: true,
    };
    this._postForm = this._postForm.bind(this);
  }

  componentDidMount() {
    axios.post("/api/fetchUserInfo", {}).then((response) => {
      if (response.data) {
        this.setState({ loading: false, ...response.data });
      } else {
        this.setState({ loading: false });
        console.log(response.data);
        //TODO : better handle server errors
      }
    });
  }

  _postForm(event) {
    event.preventDefault;
    const {
      match: { params },
    } = this.props;
    this.setState({ postFormSpinner: true });
    axios
      .post("/api/createDemand", {
        channelId: params.channelId,
        nbOfLicenses: this.state.nbOfLicences,
        description: this.state.demandDescription,
        fullName: this.state.fullName,
        organismName: this.state.organismName,
        title: this.state.title,
        department: this.state.department,
        fullAddress: this.state.fullAddress,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            postFormSpinner: false,
            message: res.data.message,
            success: true,
          });
        } else if (!res.data.success && res.data.reason == "invalid_session") {
          this.setState({ postFormSpinner: false });
          this.props.handleExpiredSession();
        } else {
          this.setState({
            postFormSpinner: false,
            errorMessage: "Une erreur est survenue",
          });
        }
      });
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirectToList === true) {
      return <Redirect to="/abonnements" />;
    } else {
      if (this.state.loading) {
        return (
          <Grid container justify="center">
            <CircularProgress className={classes.bigSpinner} size={48} />
          </Grid>
        );
      } else {
        return (
          <Container component="main" maxWidth="md">
            <CssBaseline />
            {this.state.success ? (
              <div className={classes.paper}>
                <Typography component="h5" variant="h5" align="center">
                  Votre demande d'accès a été créée avec succès. Nous vous
                  contacterons suite à son approbation. Vous pouvez suivre en
                  tout temps le statut de votre demande sur votre{" "}
                  <Typography
                    className={`${classes.margin} ${classes.link}`}
                    variant="h5"
                    color="inherit"
                    component={RouterLink}
                    to="/abonnements"
                  >
                    tableau de bord utilisateur
                  </Typography>
                  .
                </Typography>
              </div>
            ) : (
              <div className={classes.paper}>
                <Typography component="h5" variant="h5">
                  Faire une demande d'accès
                </Typography>
                <ValidatorForm
                  className={classes.form}
                  onError={(errors) => console.log("errors", errors)}
                  onSubmit={this._postForm}
                >
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="fullName"
                    label="Nom du responsable"
                    name="fullName"
                    autoFocus
                    value={this.state.fullName}
                    onChange={(e) =>
                      this.setState({ fullName: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="title"
                    label="Titre du responsable"
                    name="title"
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="organismName"
                    label="Nom de l'organisme"
                    name="organismName"
                    value={this.state.organismName}
                    onChange={(e) =>
                      this.setState({ organismName: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="department"
                    label="Direction/Département"
                    name="department"
                    value={this.state.department}
                    onChange={(e) =>
                      this.setState({ department: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="fullAddress"
                    label="Adresse complète"
                    name="fullAddress"
                    value={this.state.fullAddress}
                    onChange={(e) =>
                      this.setState({ fullAddress: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="demandDescription"
                    label="Description de l'utilisation"
                    id="demandDescription"
                    value={this.state.demandDescription}
                    onChange={(e) =>
                      this.setState({ demandDescription: e.target.value })
                    }
                    validators={["required"]}
                    errorMessages={["Vous devez remplir ce champs"]}
                    multiline
                    rows={6}
                  />
                  {this.state.errorMessage != "" && (
                    <Typography
                      className={classes.errorMessage}
                      component="h6"
                      variant="subtitle1"
                      align="center"
                      color="secondary"
                    >
                      {this.state.errorMessage}
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.postFormSpinner}
                  >
                    Soumettre la demande
                    {this.state.postFormSpinner ? (
                      <CircularProgress className={classes.spinner} size={16} />
                    ) : null}
                  </Button>
                  <p>
                    La demande d&apos;accès sera acceptée si les instructions
                    suivantes sont respectées :
                  </p>
                  <ul>
                    <li>
                      Le formulaire doit être rempli par et pour un organisme
                      public, incluant les ministères, municipalités et
                      institutions d&apos;enseignement.{" "}
                    </li>
                    <li>
                      Les étudiants doivent faire la demande par
                      l&apos;intermédiaire de leur professeur.
                    </li>
                    <li>
                      La description de l&apos;utilisation doit être
                      suffisamment étoffée pour déterminer si le fichier de
                      l&apos;Enquête peut effectivement être utilisé à cette
                      fin.
                    </li>
                    <li>
                      Aucune demande ne sera acceptée pour les comptes utilisant
                      un courriel gratuit (ex. : Gmail, Hotmail, etc.).
                    </li>
                  </ul>
                </ValidatorForm>
              </div>
            )}
          </Container>
        );
      }
    }
  }
}

export default withStyles(styles)(AccessForm);
