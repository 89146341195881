import React, { Component } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";

import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AdminDemandCard from "./cards/AdminDemandCard";
import AdminChannelCard from "./cards/AdminChannelCard";
import AdminCategoryCard from "./cards/AdminCategoryCard";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";

import CircularProgress from "@material-ui/core/CircularProgress";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import axios from "axios";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200,
  },
  grid: {
    width: 1000,
  },
  topMenu: {
    marginTop: 20,
  },
  margins: {
    marginLeft: 16,
    marginRight: 16,
  },
  link: {
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  spinner: {
    marginTop: theme.spacing(6),
  },
  backdrop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    zIndex: 1,
    opacity: "0.8!important",
  },
  gridMargin: {
    maxWidth: 1000,
  },
});

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "demands",
      activeCategories: [],
      channels: [],
      demands: [],
      categories: [],
      approvedDemands: [],
      pageLoading: true,
      loading: false,
      myDemandsAccordionOpen: true,
    };

    this.createChannel = this.createChannel.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this._resetSelectedCategory = this._resetSelectedCategory.bind(this);

    this._closeForm = this._closeForm.bind(this);
    this._openForm = this._openForm.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData() {
    const fetchChannels = axios.post("/api/admin/fetchChannels", {});
    const fetchAdminActiveDemands = axios.post(
      "/api/admin/fetchAdminActiveDemands",
      {}
    );
    const fetchApprovedDemands = axios.post(
      "/api/admin/fetchApprovedDemands",
      {}
    );
    const fetchCategories = axios.post("/api/admin/fetchCategories", {});
    const fetachAdmins = axios.post("/api/admin/fetchAdmins", {});
    const fetchRefusedDemands = axios.post(
      "/api/admin/fetchRefusedDemands",
      {}
    );
    Promise.all([
      fetchChannels,
      fetchAdminActiveDemands,
      fetchCategories,
      fetachAdmins,
      fetchApprovedDemands,
      fetchRefusedDemands,
    ]).then((responses) => {
      if (responses.filter((r) => !!r.data).length > 0) {
        this.setState({
          pageLoading: false,
          channels: responses[0].data,
          demands: responses[1].data,
          categories: responses[2].data,
          admins: responses[3].data,
          approvedDemands: responses[4].data,
          refusedDemands: responses[5].data,
        });
      } else {
        this.props.history.push("/seConnecter");
      }
    });
  }

  _resetSelectedCategory() {
    this.setState({ activeCategories: [] });
  }

  _closeForm(categoryId) {
    this.setState((prevState) => ({
      activeCategories: prevState.activeCategories.filter(
        (category) => category != categoryId
      ),
    }));
  }

  _openForm(categoryId) {
    this.setState((prevState) => ({
      activeCategories: prevState.activeCategories.concat(categoryId),
    }));
  }

  createChannel(categoryId) {
    this.setState({ loading: true });
    axios
      .post("/api/admin/createChannel", { categoryId: categoryId })
      .then((res) => {
        if (res.data) {
          this.props.history.push(`/admin/depot/${res.data._id}`);
        }
      });
  }

  createCategory() {
    this.setState({ loading: true });
    axios.post("/api/admin/createCategory", {}).then((res) => {
      this.setState((prevState) => ({
        loading: false,
        categories: res.data.categories,
        activeCategories: prevState.activeCategories.concat(
          res.data.activeCategory
        ),
      }));
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <CircularProgress className={classes.spinner} size={48} />
          </Backdrop>
          {this.state.pageLoading ? (
            <Grid container justify="center">
              <CircularProgress className={classes.spinner} size={48} />
            </Grid>
          ) : (
            <div>
              <Grid container justify="center" className={classes.topMenu}>
                <Typography
                  variant="h6"
                  className={
                    this.state.currentPage != "demands" ? classes.link : ""
                  }
                  color="inherit"
                  onClick={() => this.setState({ currentPage: "demands" })}
                >
                  Demandes d'accès
                </Typography>
                <Typography
                  variant="h6"
                  className={`${classes.margins} ${
                    this.state.currentPage != "channels" ? classes.link : ""
                  }`}
                  color="inherit"
                  onClick={() => this.setState({ currentPage: "channels" })}
                >
                  Dépôts
                </Typography>
                <Typography
                  variant="h6"
                  className={
                    this.state.currentPage != "categories" ? classes.link : ""
                  }
                  color="inherit"
                  onClick={() => this.setState({ currentPage: "categories" })}
                >
                  Catégories
                </Typography>
              </Grid>
              <div className={classes.root}>
                {this.state.currentPage == "demands" && (
                  <Grid container justify="center">
                    <Grid
                      alignItems="center"
                      justify="center"
                      container
                      className={classes.gridMargin}
                    >
                      <Grid item xs={12}>
                        <Accordion
                          expanded={this.state.myDemandsAccordionOpen}
                          onChange={() => {
                            this.setState({
                              myDemandsAccordionOpen:
                                !this.state.myDemandsAccordionOpen,
                            });
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h6">
                              Demandes en attente
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              spacing={3}
                              alignItems="center"
                              justify="center"
                              container
                            >
                              {this.state.demands.length > 0 ? (
                                this.state.demands
                                  .filter((d) => d.user)
                                  .map((d) => (
                                    <Grid item xs={12} key={d._id}>
                                      <AdminDemandCard
                                        key={d._id}
                                        demandId={d._id}
                                        description={d.description}
                                        user={d.user}
                                        approver={d.onDeckUser && d.onDeckUser['email']}
                                        date={d.receivedOn}
                                        category={
                                          this.state.categories.find(
                                            (c) => c._id == d.category
                                          )["categoryName"]
                                        }
                                      />
                                    </Grid>
                                  ))
                              ) : (
                                <Grid item xs={12}>
                                  <Typography
                                    xs={12}
                                    variant="h6"
                                    className={classes.marginTop}
                                  >
                                    Aucune demande en attente
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          disabled={
                            this.state.approvedDemands.filter((d) => d.onDeck)
                              .length == 0
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h6">
                              Demandes approuvées et en attente d'approbation
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              spacing={3}
                              alignItems="center"
                              justify="center"
                              container
                            >
                              {this.state.approvedDemands.filter(
                                (d) => d.onDeck
                              ).length > 0 ? (
                                this.state.approvedDemands
                                  .filter((d) => d.onDeck)
                                  .map((d) => (
                                    <Grid item xs={12} key={d._id}>
                                      <AdminDemandCard
                                        key={d._id}
                                        demandId={d._id}
                                        description={d.description}
                                        user={d.user}
                                        category={
                                          this.state.categories.find(
                                            (c) => c._id == d.category
                                          )["categoryName"]
                                        }
                                        isApproved={true}
                                        awaitingUserApproval={d.approved}
                                        onDeck={d.onDeck && d.onDeck["email"]}
                                        lastApproval={
                                          d.lastNotificationSentOn
                                            ? new Date(d.lastNotificationSentOn)
                                            : d.approvalsBy
                                                .map((a) => new Date(a.date))
                                                .sort((a, b) => {
                                                  return b - a;
                                                })[0]
                                        }
                                      />
                                    </Grid>
                                  ))
                              ) : (
                                <Typography
                                  variant="h6"
                                  className={classes.marginTop}
                                >
                                  Aucune demande approuvée incomplète
                                </Typography>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          disabled={
                            this.state.approvedDemands.filter((d) => !d.onDeck)
                              .length == 0
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h6">
                              Demandes en attente de la signature du demandeur
                              ou complétées
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              spacing={3}
                              alignItems="center"
                              justify="center"
                              container
                            >
                              {this.state.approvedDemands.filter(
                                (d) => !d.onDeck
                              ).length > 0 ? (
                                this.state.approvedDemands
                                  .sort((a, b) => a.closed - b.closed)
                                  .filter((d) => !d.onDeck)
                                  .map((d) => (
                                    <Grid item xs={12} key={d._id}>
                                      <AdminDemandCard
                                        key={d._id}
                                        demandId={d._id}
                                        description={d.description}
                                        user={d.user}
                                        category={
                                          this.state.categories.find(
                                            (c) => c._id == d.category
                                          )["categoryName"]
                                        }
                                        contractAcceptance={
                                          d.contractAcceptance &&
                                          new Date(d.contractAcceptance)
                                        }
                                        closed={d.closed}
                                        isApproved={true}
                                        awaitingUserApproval={d.approved}
                                        onDeck={d.onDeck && d.onDeck["email"]}
                                        lastApproval={
                                          d.lastNotificationSentOn
                                            ? new Date(d.lastNotificationSentOn)
                                            : d.approvalsBy
                                                .map((a) => new Date(a.date))
                                                .sort((a, b) => {
                                                  return b - a;
                                                })[0]
                                        }
                                      />
                                    </Grid>
                                  ))
                              ) : (
                                <Typography
                                  variant="h6"
                                  className={classes.marginTop}
                                >
                                  Aucune demande approuvée en attente de la
                                  signature du demandeur ou complétée
                                </Typography>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          disabled={
                            this.state.refusedDemands.filter((d) => !d.onDeck)
                              .length == 0
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h6">
                              Demandes refusées
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              spacing={3}
                              alignItems="center"
                              justify="center"
                              container
                            >
                              {this.state.refusedDemands.filter(
                                (d) => !d.onDeck
                              ).length > 0 ? (
                                this.state.refusedDemands
                                  .sort((a, b) => a.closed - b.closed)
                                  .filter((d) => !d.onDeck)
                                  .map((d) => (
                                    <Grid item xs={12} key={d._id}>
                                      <AdminDemandCard
                                        key={d._id}
                                        demandId={d._id}
                                        description={d.description}
                                        user={d.user}
                                        category={
                                          this.state.categories.find(
                                            (c) => c._id == d.category
                                          )["categoryName"]
                                        }
                                        contractAcceptance={
                                          d.contractAcceptance &&
                                          new Date(d.contractAcceptance)
                                        }
                                        closed={d.closed}
                                        isApproved={false}
                                        isRefused={true}
                                        awaitingUserApproval={d.approved}
                                        onDeck={d.onDeck && d.onDeck["email"]}
                                        lastApproval={
                                          d.lastNotificationSentOn
                                            ? new Date(d.lastNotificationSentOn)
                                            : d.approvalsBy
                                                .map((a) => new Date(a.date))
                                                .sort((a, b) => {
                                                  return b - a;
                                                })[0]
                                        }
                                      />
                                    </Grid>
                                  ))
                              ) : (
                                <Typography
                                  variant="h6"
                                  className={classes.marginTop}
                                >
                                  Aucune demande refusée
                                </Typography>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {this.state.currentPage == "channels" && (
                  <Grid container justify="center">
                    <Grid
                      spacing={3}
                      alignItems="center"
                      justify="center"
                      container
                      className={classes.grid}
                    >
                      {this.state.categories.map((category) => (
                        <React.Fragment
                          key={`fragment_channel_category_${category._id}`}
                        >
                          <Grid
                            key={`channel_category_${category._id}`}
                            item
                            xs={12}
                          >
                            <Typography variant="h6">
                              {`${category.categoryName}`}
                            </Typography>
                          </Grid>
                          <DragDropContext
                            onDragEnd={(result) => {
                              if (result.destination) {
                                this.setState({ loading: true });
                                const originalChannels = this.state.channels;
                                const channelPosition = this.state.channels
                                  .filter((c) => c.category == category._id)
                                  .sort((a, b) => a.sortOrder - b.sortOrder);
                                const draggedChannel = result.draggableId;
                                const originalIndex = result.source.index;
                                const newIndex = result.destination.index;
                                const [removed] = channelPosition.splice(
                                  originalIndex,
                                  1
                                );
                                channelPosition.splice(newIndex, 0, removed);
                                const channelsWithIndex = channelPosition.map(
                                  (cp, index) => ({ ...cp, sortOrder: index })
                                );

                                this.setState(
                                  (prevState) => ({
                                    channels: prevState.channels.map(
                                      (channel) =>
                                        channelsWithIndex.find(
                                          (cwi) => cwi._id === channel._id
                                        ) || channel
                                    ),
                                  }),
                                  () => {
                                    const channelUpdate = channelsWithIndex.map(
                                      (c) => ({
                                        _id: c._id,
                                        sortOrder: c.sortOrder,
                                      })
                                    );
                                    axios
                                      .post("/api/admin/setChannelOrder", {
                                        channels: channelUpdate,
                                      })
                                      .then((response) => {
                                        if (response.data.success) {
                                          this.setState({ loading: false });
                                        } else {
                                          this.setState({
                                            loading: false,
                                            channels: originalChannels,
                                          });
                                        }
                                      });
                                  }
                                );
                              }
                            }}
                          >
                            <Droppable
                              droppableId={`droppable_channel_category_${category._id}`}
                            >
                              {(provided, snapshot) => (
                                <Grid
                                  key={`grid_category_${category._id}`}
                                  spacing={3}
                                  container
                                  item
                                  xs={12}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {this.state.channels
                                    .filter((c) => c.category == category._id)
                                    .sort((a, b) => a.sortOrder - b.sortOrder)
                                    .map((c, index) => (
                                      <Draggable
                                        key={`draggable_${c._id}`}
                                        draggableId={c._id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <Grid
                                            item
                                            xs={12}
                                            key={c._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <AdminChannelCard
                                              key={c._id}
                                              channelId={c._id}
                                              channelDescription={
                                                c.channelDescription
                                              }
                                              channelName={c.channelName}
                                            />
                                          </Grid>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </Grid>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            endIcon={<AddIcon />}
                            onClick={() => this.createChannel(category._id)}
                          >
                            Ajouter un dépôt
                          </Button>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {this.state.currentPage == "categories" && (
                  <Grid container justify="center">
                    <Grid
                      spacing={3}
                      alignItems="center"
                      justify="center"
                      container
                      className={classes.grid}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Liste des catégories
                        </Typography>
                      </Grid>

                      <DragDropContext
                        onDragEnd={(result) => {
                          if (result.destination) {
                            this.setState({ loading: true });
                            const originalCategories = this.state.categories;
                            const categoryPosition = this.state.categories.sort(
                              (a, b) => a.sortOrder - b.sortOrder
                            );
                            const draggedCategory = result.draggableId;
                            const originalIndex = result.source.index;
                            const newIndex = result.destination.index;
                            const [removed] = categoryPosition.splice(
                              originalIndex,
                              1
                            );
                            categoryPosition.splice(newIndex, 0, removed);
                            const categoriesWithIndex = categoryPosition.map(
                              (cp, index) => ({ ...cp, sortOrder: index })
                            );

                            this.setState(
                              (prevState) => ({
                                categories: prevState.categories.map(
                                  (category) =>
                                    categoriesWithIndex.find(
                                      (cwi) => cwi._id === category._id
                                    ) || category
                                ),
                              }),
                              () => {
                                const categoryUpdate = categoriesWithIndex.map(
                                  (c) => ({
                                    _id: c._id,
                                    sortOrder: c.sortOrder,
                                  })
                                );
                                axios
                                  .post("/api/admin/setCategoryOrder", {
                                    categories: categoryUpdate,
                                  })
                                  .then((response) => {
                                    if (response.data.success) {
                                      this.setState({ loading: false });
                                    } else {
                                      this.setState({
                                        loading: false,
                                        categories: originalCategories,
                                      });
                                    }
                                  });
                              }
                            );
                          }
                        }}
                      >
                        <Droppable droppableId={`droppable_category`}>
                          {(provided, snapshot) => (
                            <Grid
                              key={`grid_category`}
                              spacing={3}
                              container
                              item
                              xs={12}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.categories
                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                .map((c, index) => (
                                  <Draggable
                                    key={`draggable_${c._id}`}
                                    draggableId={c._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <Grid
                                        item
                                        xs={12}
                                        key={c._id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <AdminCategoryCard
                                          key={c._id}
                                          categoryId={c._id}
                                          categoryName={c.categoryName}
                                          categoryDescription={
                                            c.categoryDescription
                                          }
                                          approvalsBy={c.approvalsBy}
                                          admins={this.state.admins}
                                          resetSelectedCategory={
                                            this._resetSelectedCategory
                                          }
                                          formIsOpen={
                                            this.state.activeCategories.indexOf(
                                              c._id
                                            ) >= 0
                                          }
                                          openForm={this._openForm}
                                          closeForm={this._closeForm}
                                          fetchData={this._fetchData}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        endIcon={<AddIcon />}
                        onClick={() => this.createCategory()}
                      >
                        Ajouter une catégorie
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AdminDashboard);
