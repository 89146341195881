import React, { Component } from "react";

import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    maxWidth: 1000,
    cursor: "pointer",
  },
});

class CategoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, name, description, onClick } = this.props;
    return (
      <Card className={classes.root} onClick={onClick}>
        <CardHeader title={name} />
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing
          style={{ width: "100%", justifyContent: "flex-end" }}
        >
          <Button>Accéder à ce dépôt</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(CategoryCard);
