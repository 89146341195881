import React, {Component} from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router, 
  Link as RouterLink,
  Redirect
} from 'react-router-dom';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/styles/withStyles";
import Container from '@material-ui/core/Container';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
});

class ActivateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      message: null,
      redirectToReferrer: false
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    axios.post('/api/activateAccount', {token: params['token']}).then((res) => {
      if (res.data.success) {
        this.setState({message: "Votre compte a été activé avec succès. Vous serez redirigés sous peu vers la page de connexion", showSpinner: false})
        setTimeout(() => {this.setState({redirectToReferrer: true})}, 4000)
      } else {
        this.setState({message: res.data.message, showSpinner: false})
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { match: { params } } = this.props;
    if (this.state.redirectToReferrer === true) {
      return <Redirect to="/seConnecter" />
    } else {
      return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper} >
              {this.state.showSpinner && <CircularProgress />}
              <Typography component="h1" variant="h5" align="center">
                {this.state.message}
              </Typography>
            </div>
        </Container>
      );
    }
  }
}

export default withStyles(styles)(ActivateAccount);