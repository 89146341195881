import React, { Component } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Redirect,
} from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Recaptcha from "react-recaptcha";
import config from "../../config/client.json";

let recaptchaInstance;

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
  },
  secondParagraph: {
    marginTop: theme.spacing(1),
  },
});

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passwordConfirmation: "",
      captchaToken: null,
      errorMessage: null,
      loading: false,
      success: false,
      showPassword: false,
      showRepeatPassword: false,
    };

    this._register = this._register.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  _register(event) {
    event.preventDefault();
    this.setState({
      errorMessages: null,
      loading: true,
    });
    axios
      .post("/api/register", {
        email: this.state.email,
        password: this.state.password,
        captchaToken: this.state.captchaToken,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            email: "",
            password: "",
            passwordConfirmation: "",
            loading: false,
            success: true,
          });
        } else {
          this.setState(
            {
              loading: false,
              errorMessage: res.data.message,
            },
            () => {
              recaptchaInstance.reset();
            }
          );
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {this.state.success ? (
          <div className={classes.paper}>
            <Typography component="h5" variant="h5" align="center">
              <div>Votre compte a été créé avec succès.</div>
              <div className={classes.secondParagraph}>
                Vous recevrez bientôt un courriel vous permettant de l'activer.
              </div>
            </Typography>
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Créer un compte
              </Typography>
              <ValidatorForm
                className={classes.form}
                onError={(errors) => console.log("errors", errors)}
                onSubmit={this._register}
              >
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Courriel"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Vous devez remplir ce champs",
                    "Vous devez entrer une adresse courriel valide",
                  ]}
                />
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  validators={["required", "minStringLength: 8"]}
                  errorMessages={[
                    "Vous devez remplir ce champs",
                    "Veuillez choisir un mot de passe de 8 caractères ou plus",
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Activer/Désactiver l'affichage du mot de passe"
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                          onMouseDown={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                          tabIndex="-1"
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.password != "" ? (
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="passwordConfirmation"
                    label="Répétez le mot de passe"
                    type={this.state.showRepeatPassword ? "text" : "password"}
                    id="passwordConfirmation"
                    autoComplete="current-password"
                    onChange={(e) =>
                      this.setState({ passwordConfirmation: e.target.value })
                    }
                    value={this.state.passwordConfirmation}
                    validators={["isPasswordMatch", "required"]}
                    errorMessages={[
                      "Le mot de passe ne correspond pas",
                      "Vous devez remplir ce champs",
                    ]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Activer/Désactiver l'affichage du mot de passe"
                            onClick={() =>
                              this.setState({
                                showRepeatPassword:
                                  !this.state.showRepeatPassword,
                              })
                            }
                            onMouseDown={() =>
                              this.setState({
                                showRepeatPassword:
                                  !this.state.showRepeatPassword,
                              })
                            }
                            tabIndex="-1"
                          >
                            {this.state.showRepeatPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
                <Box display="flex" width="100%">
                  <Box m="auto">
                    <Recaptcha
                      sitekey={config.publicCaptchaToken}
                      hl="fr"
                      ref={(e) => (recaptchaInstance = e)}
                      render="explicit"
                      onloadCallback={() => console.log("captcha rendered")}
                      verifyCallback={(response) => {
                        this.setState({ captchaToken: response });
                      }}
                    />
                  </Box>
                </Box>
                {this.state.errorMessage != "" && (
                  <Typography
                    className={classes.errorMessage}
                    component="h6"
                    variant="subtitle1"
                    align="center"
                    color="secondary"
                  >
                    {this.state.errorMessage}
                  </Typography>
                )}
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={this.state.loading}
                >
                  Créer le compte
                  {this.state.loading ? (
                    <CircularProgress className={classes.spinner} size={16} />
                  ) : null}
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/seConnecter"
                      variant="body2"
                      style={{ fontSize: 16 }}
                    >
                      {"Vous possédez déjà un compte? Ouvrez une session!"}
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
            <Box mt={8}>{/*<Copyright />*/}</Box>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(Signup);
