import React, { Component } from 'react';

import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';

import axios from 'axios';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["A500"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200
  },
  grid: {
    width: 1000
  },
  inlineTextInput: {
    marginTop: 8
  },
  grow: {
    flexGrow: 1,
  }

});
 
class AdminFileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDescription: props.file['fileDescription'] ? props.file['fileDescription'] : '',
      loading: false,
      loadPercentage: 0
    };

    this.deleteFile = this.deleteFile.bind(this);
    this.editFile = this.editFile.bind(this);
  }

  componentDidMount() {

  }

  commitChanges() {
    //TODO : commit the file/fileinfo to server, and update the file object that is passed to parent
    //this.props.updateFile(this.props.fileIndex,{})

  }

  deleteFile() {
    axios.post('/api/admin/deleteFile', {fileId: this.props.file._id}).then((res) => {
      this.props.deleteFile(this.props.fileIndex);
    })
  }

  editFile() {
    axios.post('/api/admin/editFile', {
      fileId: this.props.file._id, 
      fileDescription: this.state.fileDescription
    }).then((res) => {
      this.props.updateFile(this.props.fileIndex, res.data)
    })
  }

  uploadFile = e => {
    const bodyFormData = new FormData();
    bodyFormData.set('fileDescription', this.state.fileDescription);
    bodyFormData.set('fileId', this.props.file._id)
    bodyFormData.append('file', e.target.files[0]);
    this.setState({loading: true})
    axios({
      method: 'post',
      url: '/api/admin/editFile',
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: progress => {
        const {loaded, total} = progress;
        this.setState({loadPercentage: (loaded/total) * 100})
      }
    }).then((res) => {
      this.setState({loading: false, loadPercentage: 0})
    }).catch((res) => {
      console.log('err', res);
      this.setState({loading: false, loadPercentage: 0})
    })
  }
 
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
          <ListItem>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <TextField
                variant="outlined"
                margin="normal"
                required
                name="fileDescription"
                label="Description du fichier"
                size="small"
                className={classes.inlineTextInput}
                value={this.state.fileDescription}
                onChange={e => this.setState({fileDescription: e.target.value})}
                onBlur={this.editFile}
            />
            <Typography
              className={classes.grow}
            >
              Nombre de téléchargements: {parseInt(this.props.file['nbOfTimeDownloaded'])}
            </Typography>
            <Button
              component="label"
              variant="outlined"
              size="large"
            >
              Choisir un fichier
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.uploadFile}
              />
            </Button>
            <Button
              size="large"
              variant="outlined"
              disabled={this.state.loading}
              onClick={this.deleteFile}
            >
              {this.state.loading ? <CircularProgress size={24} variant="static" value={parseInt(this.state.loadPercentage)} /> : <DeleteIcon />}
            </Button>
          </ListItem>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AdminFileManager);