import React, { Component } from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import withStyles from "@material-ui/styles/withStyles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  arrowIcon: {
    fontSize: 20,
    marginLeft: 20,
  },
  cloudDownloadIcon: {
    verticalAlign: "middle",
  },
  channelListButton: {
    margin: 20,
    borderRadius: 18,
    textTransform: "none",
    fontWeight: 700,
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="md">
        <Typography variant="h4">
          Bienvenue sur le Portail données mobilité de l&apos;ARTM
        </Typography>
        <div className={classes.root}>
          <Typography component="div">
            <p>
              Ce portail vous permet d&apos;accéder aux données rendues
              disponibles par l&apos;ARTM à la communauté. Ces données sont
              agencées sous la forme de catégories et de dépôts. Certains de ces
              dépôts sont offerts en accès libre sous la forme de données
              ouvertes, alors que d&apos;autres sont à accès restreints.
            </p>
            <p>
              Pour accéder aux données des dépôts, suivez les étapes suivantes :
            </p>
            <ol>
              <li>Accédez à la liste de dépôt;</li>
              <li>
                Choisissez la catégorie, puis le dépôt qui vous intéresse;
              </li>
              <li>
                Cliquez sur l&apos;icône{" "}
                <CloudDownloadIcon className={classes.cloudDownloadIcon} /> pour
                télécharger le fichier*;
              </li>
              <li>
                Lisez et acceptez les{" "}
                <a href="https://creativecommons.org/licenses/by/4.0/legalcode.fr">
                  conditions d&apos;utilisation
                </a>
                ;
              </li>
              <li>Téléchargez le fichier;</li>
            </ol>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.channelListButton}
                component={NavLink}
                to="/depots"
              >
                Voir la liste de dépôts{" "}
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Button>
            </div>
            <p>
              * Pour certaines données, il est nécessaire de faire une demande
              d&apos;accès. Pour demander un accès, il faudra{" "}
              <Typography
                color="inherit"
                component={RouterLink}
                to="/creerUnCompte"
              >
                créer un compte
              </Typography>
              . Pour de l&apos;information supplémentaire, nous vous invitons à
              consulter la{" "}
              <Typography color="inherit" component={RouterLink} to="/faq">
                FAQ
              </Typography>
              .
            </p>
            <p>
              <strong>
                Recevoir des notifications lors des mises à jour :
              </strong>
              <br />
              Si vous souhaitez recevoir un courriel lorsqu&apos;une liste de
              dépôt est mise à jour, vous devez{" "}
              <Typography
                color="inherit"
                component={RouterLink}
                to="/creerUnCompte"
              >
                créer un compte
              </Typography>
              , puis vous connecter. L&apos;option « S&apos;abonner » sera alors
              disponible.
            </p>
          </Typography>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(Home);
